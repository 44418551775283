import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'hfc-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss']
})
export class TaskItemComponent {
  @Input()
  public heading: string;

  @Input()
  public description: string;

  @Input()
  public iconName: string;

  @Input()
  public date: Date;

  @Input()
  public status: string;

  @Input()
  public buttonLabel: string;

  @Output()
  public buttonClick = new EventEmitter();
}
