<div class="container">
  <div class="top">
    <i *ngIf="!isFactfind" class="material-icons">insert_drive_file</i>
    <i *ngIf="task.type === 'FACT_FIND'" class="material-icons">home</i>
    <i *ngIf="task.type === 'PROTECTION_FACT_FIND'" class="material-icons">security</i>
    <span class="text">{{task.timestamp | date:'d MMM y'}}</span>
  </div>

  <div class="name">
    <span *ngIf="!isFactfind" class="text-big-medium">Document Request</span>
    <span *ngIf="isFactfind" class="text-big-medium">
      <ng-container *ngIf="task.type === 'FACT_FIND'">Mortgage</ng-container>
      <ng-container *ngIf="task.type === 'PROTECTION_FACT_FIND'">Protection</ng-container>
      Fact Find for
    </span>

    <span *ngIf="!isFactfind" class="text-big">{{getDocumentLabel()}}</span>
    <span *ngIf="isFactfind" class="text-big">{{task.reference}}</span>
  </div>

  <div class="bottom">
    <span *ngIf="!isFactfind" class="progress-bar document-status">{{TASK_STATUS_NAMES.get(task.status)}}</span>
    <hfc-progress-bar *ngIf="isFactfind" [value]="task.archive ? 100 : task.percentageCompleted" class="progress-bar"></hfc-progress-bar>

    <ng-container *ngIf="!task.archive; else archiveBlock">
      <hfc-button *ngIf="!isFactfind" type="primary" [routerLink]="['/provide-document', task.token]" class="bottom__button">Provide Document</hfc-button>
      <hfc-button *ngIf="isFactfind" type="primary" [isLoading]="isLoading" (click)="onGoToFactfindClicked()" class="bottom__button">Go to Fact Find</hfc-button>
    </ng-container>
    <ng-template #archiveBlock>
      <hfc-button *ngIf="!isFactfind" type="primary" (click)="onArchiveViewClicked()" class="bottom__button">View</hfc-button>
    </ng-template>
  </div>
</div>

