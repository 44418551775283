import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

import { fieldsMatcher } from '../../../../../../../src/app/website/utils/form.validators';
import { ActivatedRoute } from '@angular/router';
import { AuthService, UserInvitation } from '../../services/auth.service';
import { ToastService } from '../../../shared/services/toast.service';
import { NavigationService } from '../../services/navigation.service';
import { UserService } from '../../services/user.service';

function isPasswordSecure(password: string): boolean {
  return password.length >= 8
    && /\d/.test(password)
    && /[A-Za-z]/.test(password);
}

const isSecure: ValidatorFn = (control: AbstractControl) => {
  const password: string = control.value;

  const result = isPasswordSecure(password)
    ? null
    : { "secure": true }

  return result;
}

@Component({
  selector: 'hfc-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  public get token() { return this.route.snapshot.queryParamMap.get("token") }
  public get resetToken() { return this.route.snapshot.queryParamMap.get("reset-token") }
  public invitation: UserInvitation;
  public errorMessage: string;
  public isLoading = true;
  public isSubmitting = false;
  public isReset = false;

  public form: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, isSecure]),
    confirmPassword: new FormControl('', Validators.required)
  }, fieldsMatcher("password", "confirmPassword"));

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastService: ToastService,
    private userService: UserService,
    private navigationService: NavigationService
  ) {
    this.isReset = !!this.resetToken;


    this.getInvitation();
  }

  public async onSubmit() {
    this.isSubmitting = true;

    try {
      const password = this.form.get("password").value;
      const session = this.isReset
        ? await this.authService.resetPassword(this.resetToken, password)
        : await this.authService.setPassword(this.token, password)

      this.userService.setUser(session);

      this.toastService.add("Password has been set.");
      this.navigationService.goToDashboard();
    } catch (error) {
      this.toastService.add(`Could not set password: ${error.error.error.message}`);
    } finally {
      this.isSubmitting = false;
    }
  }

  private async getInvitation() {
    try {
      this.invitation = this.isReset
        ? await this.authService.getResetPasswordInfo(this.resetToken)
        : await this.authService.getInvitationData(this.token);
    } catch (error) {
      this.errorMessage = error.error.error.message;
    }

    this.isLoading = false;
  }
}
