import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentRoutingModule } from './document-routing.module';
import { DocumentLayoutComponent } from './components/document-layout/document-layout.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientSharedModule } from '../shared/client-shared.module';

@NgModule({
  declarations: [DocumentLayoutComponent],
  imports: [
    CommonModule,
    DocumentRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ClientSharedModule,
  ]
})
export class DocumentModule { }
