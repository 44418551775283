import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Attachment } from "../../../../../../src/app/website/models/attachment";
import { DocumentTypeDTO, ProvidedDocumentDTO } from "../../../../../../src/app/website/models/document";
import { Environment } from "../../../../../../src/app/website/services/environment.service";
import { GENERAL_DOCUMENT_TYPE } from "../../../../../../src/app/website/utils/variables.data";
import { ApiService } from "../../root/services/api.service";
import { User } from "../../root/services/user.service";

export interface DocumentRequestType {
  type: GENERAL_DOCUMENT_TYPE;
  otherTypeLabel?: string;
}

export interface DocumentRequest {
  messageToClient: string;
  document: DocumentRequestType;
  requestedFrom: User;
  requestedBy: User;
  requestedTypes: DocumentTypeDTO[];
  providedDocuments: ProvidedDocumentDTO[];
}

@Injectable({ providedIn: "root" })
export class DocumentApiService {
  private readonly API_NAME = "document";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private environment: Environment
  ) { }

  public async getDocumentRequestInfo(token: string): Promise<DocumentRequest> {
    return this.apiService
      .get(this.API_NAME, "getDocumentRequestInfo", { params: { token } })
      .toPromise();
  }

  public async acceptRequestedDocumentByToken(token: string, document: Attachment): Promise<any> {
    return this.apiService
      .post(this.API_NAME, "acceptRequestedDocumentByToken", document, { params: { token } })
      .toPromise();
  }

  public async provideRequestedDocument(token: string, type: DocumentRequestType, file: File): Promise<any> {
    const url = `${this.environment.apiUrl}servlet/provideRequestedDocument`;
    const params = { token, filename: file.name, type: type.type, otherTypeLabel: type.otherTypeLabel };

    return this.http
      .post(url, file, { params })
      .toPromise();
  }

  public async completeDocumentRequest(token: string): Promise<any> {
    return this.apiService
      .post(this.API_NAME, "completeDocumentRequest", undefined, { params: { token } })
      .toPromise();
  }

  public async deleteProvidedFile(token: string, fileId: string): Promise<any> {
    return this.apiService
      .delete(this.API_NAME, "deleteProvidedFile", { params: { token, fileId } })
      .toPromise();
  }

  public triggerDownload(document: Attachment) {
    const url = `${this.environment.apiUrl}servlet/gcs/${document.folder}/${document.filename}?download=true`;

    window.open(url);
  }
}
