import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { SignupComponent } from './components/signup/signup.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { AuthOptionalGuard } from './services/auth-optional.guard';
import { AuthRequiredGuard } from './services/auth-required.guard';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { UnsubscribeDoneComponent } from './components/unsubscribe-done/unsubscribe-done.component';
import { UnsubscribeAbortComponent } from './components/unsubscribe-abort/unsubscribe-abort.component';
import { ErrorComponent } from './components/error/error.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      documentTitle: "Login"
    }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      documentTitle: "Logout"
    }
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: {
      documentTitle: "Create Password"
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      documentTitle: "Forgot Password"
    }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../app/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthRequiredGuard],
    data: {
      documentTitle: "Dashboard"
    }
  },
  {
    path: 'fact-find',
    loadChildren: () => import('../../app/fact-find/fact-find.module').then(m => m.FactFindModule),
    canActivate: [AuthRequiredGuard]
  },
  {
    path: "protection-fact-find",
    loadChildren: () => import("../protection-fact-find/protection-fact-find.module").then(m => m.ProtectionFactFindModule),
    canActivate: [AuthRequiredGuard]
  },
  {
    path: 'provide-document',
    loadChildren: () => import('../../app/document/document.module').then(m => m.DocumentModule),
    canActivate: [AuthOptionalGuard],
    data: {
      documentTitle: "Document Request"
    }
  },
  {
    path: "signature",
    loadChildren: () => import("../signature/signature.module").then(m => m.SignatureModule),
    canActivate: [AuthOptionalGuard]
  },
  {
    path: 'thank-you',
    component: ThankYouComponent,
    data: {
      documentTitle: "Thank you"
    }
  },
  {
    path: "error",
    component: ErrorComponent,
  },
  {
    path: "unsubscribe",
    component: UnsubscribeComponent,
    data: {
      method: "unsubscribe"
    }
  },
  {
    path: "unsubscribe-mortgage",
    component: UnsubscribeComponent,
    data: {
      method: "unsubscribeMortgage"
    }
  },
  {
    path: "unsubscribe-remortgage",
    component: UnsubscribeComponent,
    data: {
      method: "unsubscribeRemortgage"
    }
  },
  {
    path: "unsubscribe-reminder",
    component: UnsubscribeComponent,
    data: {
      method: "unsubscribeReminder"
    }
  },
  {
    path: "unsubscribe-done",
    component: UnsubscribeDoneComponent,
    data: {
      title: "All notifications disabled",
      message: "Please get in touch if you would like to resubscribe."
    }
  },
  {
    path: "unsubscribe-mortgage-done",
    component: UnsubscribeDoneComponent,
    data: {
      title: "All notifications disabled",
      message: "You will still receive notifications relevant to you for this mortgage."
    }
  },
  {
    path: "unsubscribe-remortgage-done",
    component: UnsubscribeDoneComponent,
    data: {
      title: "You have opted out of remortgage information",
      message: "Please note that we are still obliged by the Financial Conduct Authority to notify you of your upcoming product end date nearer the time, regardless of your preference."
    }
  },
  {
    path: "unsubscribe-reminder-done",
    component: UnsubscribeDoneComponent,
    data: {
      title: "This reminder has been disabled",
    }
  },
  {
    path: "unsubscribe-abort",
    component: UnsubscribeAbortComponent,
    data: {
      message: "You will continue to receive notifications.",
      showLogin: false,
    }
  },
  {
    path: "unsubscribe-mortgage-abort",
    component: UnsubscribeAbortComponent,
    data: {
      message: "You will continue to receive notifications.",
      showLogin: true,
    }
  },
  {
    path: "unsubscribe-remortgage-abort",
    component: UnsubscribeAbortComponent,
    data: {
      message: "You will continue to receive remortgage information",
      showLogin: true,
    }
  },
  {
    path: "unsubscribe-reminder-abort",
    component: UnsubscribeAbortComponent,
    data: {
      message: "You will continue to receive this reminder.",
      showLogin: true,
    }
  },
  {
    path: ":referralCode",
    loadChildren: () => import('../../app/self-referral/self-referral.module').then(m => m.SelfReferralModule),
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

const extraOption: ExtraOptions = {
  enableTracing: false,
  paramsInheritanceStrategy: "always",
}

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOption)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
