import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { RouterDataResolved } from "@ngxs/router-plugin";
import { Action, Selector, State, StateContext } from "@ngxs/store";

import { APPLICATION_STEP_NAME, APPLICATION_STEP_TITLE } from "../../fact-find/enums.module";
import { FactFindStepRouteData } from "../../fact-find/fact-find-step.routes";
import { getLastActivatedRouteSnapshotData } from "../../fact-find/functions/get-last-activated-route-snapshot-data";
import { PROTECTION_APPLICATION_STEP_NAME } from "../../protection-fact-find/protection-fact-find.enum";

export interface AppStateModel {
  documentTitle: string;
  pageTitle: string;
}

@State<AppStateModel>({
  name: "app",
  defaults: {
    documentTitle: "Heron Financial",
    pageTitle: ""
  }
})
@Injectable()
export class AppState {

  @Selector()
  static pageTitle(state: AppStateModel) {
    return state.pageTitle;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  @Action(RouterDataResolved)
  public routerDataResolved(ctx: StateContext<AppStateModel>, action: RouterDataResolved) {
    const data: FactFindStepRouteData = getLastActivatedRouteSnapshotData(action.routerState.root)
    const pageTitle = APPLICATION_STEP_TITLE.get(data.step)
      || APPLICATION_STEP_NAME.get(data.step)
      || PROTECTION_APPLICATION_STEP_NAME.get(data.step as any)
      || data.documentTitle;

    const composeDocumentStepTitle = () => {
      if (APPLICATION_STEP_TITLE.get(data.step) || APPLICATION_STEP_NAME.get(data.step) || PROTECTION_APPLICATION_STEP_NAME.get(data.step as any)) {
        return `Heron Financial - ${pageTitle} - Fact Find`;
      }

      return pageTitle
        ? `Heron Financial - ${pageTitle}`
        : "Heron Financial";
    }
    const documentTitle = composeDocumentStepTitle() || pageTitle;

    documentTitle && ctx.patchState({ documentTitle, pageTitle });

    this.document.body.scrollTop = 0;
  }
}
