export let PAGE_SIZES = [10, 20, 30, 100];
export let DAYS_SHORT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export type AppointmentSpecificTime =
  | "SPECIFIC_TIME"
  | "MORNING"
  | "AFTERNOON"
  | "ANY_TIME"

export const AppointmentSpecificTimeName = new Map<AppointmentSpecificTime, string>([
  ["SPECIFIC_TIME", "SPECIFIC_TIME"],
  ["MORNING", "Morning"],
  ["AFTERNOON", "Afternoon"],
  ["ANY_TIME", "Anytime"],
]);

export type MicrosoftLinkState = 
  | "SETUP"
  | "NOT_SETUP"

export type USER_TYPE =
  | "ADMINISTRATOR"
  | "LINE_MANAGER"
  | "GENERAL"
  | "ADVISOR_MORTGAGES"
  | "CASE_PROGRESSION"
  | "SALES_ADVISOR"
  | "CLIENT"
  | "ADVISOR_PROTECTION"
  | "ADVISOR_BOTH"
  | "COMPLIANCE"
  | "APPLICATIONS"
  | "GENERAL_INSURANCE"

  | "SALES_DIRECTOR"
  | "SALES_MANAGER"
  | "SALES_CONSULTANT"
  | "INTRODUCER_ADMIN"
  | "INTRODUCER_PROGRESSIONS"
  | "INTRODUCER_OTHER"

export const USER_TYPE_NAMES = new Map<USER_TYPE, string>([
  ["ADMINISTRATOR", "Administrator"],
  ["LINE_MANAGER", "Line Manager"],
  ["GENERAL", "General"],
  ["ADVISOR_MORTGAGES", "Advisor - Mortgages"],
  ["CASE_PROGRESSION", "Case Progression"],
  ["SALES_ADVISOR", "Sales Advisor"],
  ["CLIENT", "Client"],
  ["ADVISOR_PROTECTION", "Advisor - Protection"],
  ["ADVISOR_BOTH", "Advisor - Both"],
  ["COMPLIANCE", "Compliance"],
  ["APPLICATIONS", "Applications"],
  ["GENERAL_INSURANCE", "General Insurance"],

  ["SALES_DIRECTOR", "Sales Director"],
  ["SALES_MANAGER", "Sales Manager"],
  ["SALES_CONSULTANT", "Sales Consultant"],
  ["INTRODUCER_ADMIN", "Introducer Admin"],
  ["INTRODUCER_PROGRESSIONS", "Progressions"],
  ["INTRODUCER_OTHER", "Other"],
]);

export const heronUserTypes = [
  "ADMINISTRATOR",
  "LINE_MANAGER",
  "GENERAL",
  "ADVISOR_MORTGAGES",
  "CASE_PROGRESSION",
  "SALES_ADVISOR",
  "ADVISOR_PROTECTION",
  "ADVISOR_BOTH",
  "COMPLIANCE",
  "APPLICATIONS",
]

export enum INTRODUCERS_TYPES {
  ESTATE_AGENT = 'Estate Agent',
  HOUSE_BUILDER = 'House Builder',
  OTHER = 'Other'
}

export enum PROPERTY_VALUE_TYPES {
  LESS_THEN_250K = 'Less than £250k',
  BETWEEN_250K_500K = '£250k – £500k',
  BETWEEN_500K_1M = '£500k – £1M',
  MORE_THEN_1M = 'More than £1M'
}

export type PROPERTY_VALUE =
  | "LESS_THEN_250K"
  | "BETWEEN_250K_500K"
  | "BETWEEN_500K_1M"
  | "MORE_THEN_1M"

export const PROPERTY_VALUE_NAME = new Map<PROPERTY_VALUE, string>([
  ["LESS_THEN_250K", "Less than £250k"],
  ["BETWEEN_250K_500K", "£250k – £500k"],
  ["BETWEEN_500K_1M", "£500k – £1M"],
  ["MORE_THEN_1M", "More than £1M"],
])

export enum CLIENT_CATEGORY_TYPES {
  FIRST_TIME_BUYER = 'First Time Buyer',
  REMORTGAGE = 'Remortgage',
  LET_TO_BUY = 'Let to Buy',
  BUY_TO_LET = 'Buy To Let',
  MOVER = 'Mover',
  HELP_TO_BUY	= 'Help to buy',
  SHARED_OWNERSHIP =	'Shared ownership',
  DISCOUNT_MARKET_SALE ='Discount market sale',
  PURCHASE = "Purchase",
  RESIDENTIAL = 'Residential',
  OTHER = "Other",
}

export enum CLIENT_CATEGORIES_ADD_MORTGAGE {
  PURCHASE = "Purchase",
  REMORTGAGE = "Remortgage"
}

export type CLIENT_CATEGORY =
  | "PURCHASE"
  | "REMORTGAGE"
  | "PRODUCT_TRANSFER"
  | "FURTHER_ADVANCE"

export const CLIENT_CATEGORY_NAME = new Map<CLIENT_CATEGORY, string>([
  ["PURCHASE", "Purchase"],
  ["REMORTGAGE", "Remortgage"],
  ["PRODUCT_TRANSFER", "Product Transfer"],
  ["FURTHER_ADVANCE", "Further Advance"],
])

export enum CLIENT_TYPES_ALL {
  FIRST_TIME_BUYER = 'First Time Buyer',
  LET_TO_BUY = 'Let to Buy',
  BUY_TO_LET = 'Buy To Let',
  MOVER = 'Mover',
  RESIDENTIAL = 'Residential',
  OTHER = 'Other',
}

export enum CLIENT_TYPE_ADD_MORTGAGE {
  FIRST_TIME_BUYER = 'First Time Buyer',
  LET_TO_BUY = 'Let to Buy',
  BUY_TO_LET = 'Buy To Let',
  MOVER = 'Mover',
  OTHER = 'Other',
}

export type CLIENT_TYPE =
  | "FIRST_TIME_BUYER"
  | "LET_TO_BUY"
  | "BUY_TO_LET"
  | "RESIDENTIAL"
  | "MOVER"
  | "OTHER"

export const CLIENT_TYPE_NAME = new Map<CLIENT_TYPE, string>([
  // ["FIRST_TIME_BUYER", "First Time Buyer"],
  ["LET_TO_BUY", "Let to Buy"],
  ["BUY_TO_LET", "Buy to Let"],
  ["RESIDENTIAL", "Residential"],
  ["MOVER", "Mover"],
  ["OTHER", "Other"],
])

export enum CLIENT_TYPE_REMORTGAGE_ADD_MORTGAGE {
  LET_TO_BUY = 'Let to Buy',
  BUY_TO_LET = 'Buy to Let',
  RESIDENTIAL = 'Residential',
  OTHER = 'Other',
}

export enum CLIENT_SCHEME {
  HELP_TO_BUY = 'Help to buy',
  SHARED_OWNERSHIP = 'Shared ownership',
  DISCOUNT_MARKET_SALE = 'Discount market sale',
}

export type CLIENT_SCHEME_TYPE =
  | "HELP_TO_BUY"
  | "SHARED_OWNERSHIP"
  | "DISCOUNT_MARKET_SALE"

export const CLIENT_SCHEME_TYPE_NAME = new Map<CLIENT_SCHEME_TYPE, string>([
  ["HELP_TO_BUY", "Help to Buy"],
  ["SHARED_OWNERSHIP", "Shared Ownership"],
  ["DISCOUNT_MARKET_SALE", "Discount Market Sale"],
])

export enum REFERRAL_TYPE_TYPES {
  VENDOR = 'Vendor',
  OTHER = 'Other',
  OFFER_QUALIFICATION = 'Offer Qualification'
}

export type REFERRAL_TYPE =
  | "VENDOR"
  | "OFFER_QUALIFICATION"
  | "EXISTING_CLIENT"
  | "OTHER"

export const REFERRAL_TYPE_NAME = new Map<REFERRAL_TYPE, string>([
  ["VENDOR", "Vendor"],
  ["OFFER_QUALIFICATION", "Offer qualification"],
  ["EXISTING_CLIENT", "Existing client"],
  ["OTHER", "Other"],
])

export enum BOOLEAN_TYPES {
  YES = 'Yes',
  NO = 'No'
}

export enum CONTACT_BY_TYPES {
  EMAIL = 'Email',
  PHONE = 'Phone',
  MESSAGE = 'Message',
}

export type CONTACT_BY =
  | "EMAIL"
  | "PHONE"
  | "MESSAGE"

export const CONTACT_BY_NAME = new Map<CONTACT_BY, string>([
  ["EMAIL", "Email"],
  ["PHONE", "Phone"],
  ["MESSAGE", "Message"],
])

export enum MORTGAGE_MENU_ACTIONS {
  'EDIT', 'ADD_DOCUMENT', 'ADD_NOTE', 'ADD_TASK', 'ADD_CONTACT_ATTEMPT', 'NOTIFICATIONS', 'REQUEST_DOCUMENT', 'NOT_PROCEEDING', 'DELETE', 'ASSIGN_ADVISOR', 'ASSIGN_CASE_PROGRESSION',
  'COMPLIANCE_HANDOVER', 'ADD_PROTECTION_CASE'
};

export enum PROTECTION_MENU_ACTIONS {
  'LOG_CONTACT', 'PREREQUISITES', 'COMPLIANCE_HANDOVER', 'ADD_NOTE', 'REPLACE', 'ADD_TASK', 'ADD_DOCUMENT', 'REQUEST_DOCUMENT',
   'NOT_PROCEEDING', 'SUITABILITY_LETTER', 'UNDO_NOT_PROCEEDING', 'DELETE'
};

export enum LOG_ATTEMPT_TYPE {
  SPOKEN_TO = 'Spoken to',
  FAILED = 'Failed'
}

export enum LOG_ATTEMPT_DIRECTION {
  INBOUND = 'Inbound',
  OUTBOUND = 'Outbound'
}

export enum LOG_ATTEMPT_SPOKEN_TO_TYPES {
  PROCEEDING = 'Proceeding',
  NOT_PROCEEDING = 'Not Proceeding',
  NOT_RIGHT_TIME = 'Not right time',
  OTHER = 'Other'
}

export enum MORTGAGE_STATES {
  NOT_ASSIGNED = 'Not Assigned',
  AWAITING_FIRST_CONTACT = 'Awaiting First Contact',
  CONTACT_ATTEMPTED = 'Contact Attempted',
  AWAITING_QUALIFICATION = 'Awaiting Qualification',
  QUALIFIED = 'Qualified',
  PARTIALLY_QUALIFIED = 'Partially Qualified',
  NOT_QUALIFIED = 'Not Qualified',
  IN_PROGRESS = 'In Progress',
  PREPARED = 'Prepared',
  SUBMITTED = 'Submitted',
  OFFERED = 'Offered',
  EXCHANGED = 'Exchanged',
  COMPLETED = 'Completed',
  REPLACEMENT_IN_PROGRESS = 'Replacing...',
  REPLACED = 'Replaced',
  NOT_PROCEEDING = 'Not Proceeding',
  DELETED = 'Deleted'
}

export type MORTGAGE_STATE_TYPE = keyof typeof MORTGAGE_STATES;

export const MORTGAGE_STATE_TYPE_NAMES = new Map<MORTGAGE_STATE_TYPE, string>(Object.entries(MORTGAGE_STATES) as any)

export enum MORTGAGE_ACTIONS {
  ASSIGN_ADVISOR = 'Assign advisor',
  ATTEMPT_CONTACT = 'Attempt contact',
  QUALIFY_CLIENT = 'Qualify Client',
  ARRANGE_APPOINTMENT = 'Arrange appointment',
  APPOINTMENT = 'Appointment',
  CONFIRM_APPOINTMENT = 'Confirm appointment',
  CLIENT_REGISTRATION = 'Register on Everglades',
  REQUEST_FACT_FIND = 'Request Fact Find',
  COMPLETE_FACT_FIND = 'Complete Fact Find',
  SIGN_FACT_FIND = 'Sign Fact Find',
  REVIEW_FACT_FIND = 'Review Fact Find',
  COMPLIANCE_HANDOVER = "Compliance handover",
  COMPLIANCE_APPROVAL = "Compliance approval",
  SUBMIT_APPLICATION = 'Submit application',
  PROGRESS_APPLICATION = 'Progress application',
  UNABLE_TO_DETERMINE = 'Unable to determine correct next action',
  NONE = 'No action',

  // TODO: delete these and all references to them
  ASSIGN_CASE_PROGRESSION = 'Assign Case progression',
  NOT_ASSIGNED = 'Not Assigned',
}

export type APPOINTMENT_STATE =
  | "PENDING"
  | "COMPLETED"
  | "CANCELED"
  | "PASSED";

export const APPOINTMENT_STATE_NAMES = new Map<APPOINTMENT_STATE, string>([
  ["PENDING", "Pending"],
  ["COMPLETED", "Completed"],
  ["CANCELED", "Cancelled"],
  ["PASSED", "Passed"]
]);

export type FACT_FIND_STATE =
  | "REQUESTED"
  | "STARTED"
  | "COMPLETED"
  | "SIGNED"
  | "RETURNED"
  | "APPROVED"

export const FACT_FIND_STATE_NAMES = new Map<FACT_FIND_STATE, string>([
  ["REQUESTED", "Requested"],
  ["STARTED", "Started"],
  ["COMPLETED", "Completed"],
  ["SIGNED", "Signed"],
  ["RETURNED", "Returned to Client"],
  ["APPROVED", "Approved"]
])

export type APPLICATION_STATE =
  | "SUBMITTED"
  | "RECEIVED_BY_LENDER"
  | "VALUATION_INSTRUCTED"
  | "VALUATION_RECEIVED"
  | "VALUATION_BOOKED"
  | "APPROVED"
  | "MORTGAGE_OFFER_ISSUED"
  | "EXCHANGED"
  | "COMPLETED"
  | "NOT_PROCEEDING"
  | "ON_RISK";

export const APPLICATION_STATE_NAMES = new Map<APPLICATION_STATE, string>([
  ["SUBMITTED", "Submitted"],
  ["RECEIVED_BY_LENDER", "Received by lender"],
  ["VALUATION_INSTRUCTED", "Valuation instructed"],
  ["VALUATION_BOOKED", "Valuation booked"],
  ["VALUATION_RECEIVED", "Valuation received"],
  ["APPROVED", "Approved"],
  ["MORTGAGE_OFFER_ISSUED", "Mortgage offer issued"],
  ["EXCHANGED", "Exchanged"],
  ["COMPLETED", "Completed"],
  ["NOT_PROCEEDING", "Application not proceeding"]
]);

export enum MORTGAGE_WAITING_ON {
  HERON = 'Heron',
  ADVISOR = 'Advisor',
  CASE_PROGRESSION = 'Case progression',
  CLIENT = 'Client',
  LENDER = 'Lender',
  COMPLIANCE = 'Compliance',
  NONE = 'None'
}

export type JOURNAL_ACTIVITY =
  | "CONTACT_ATTEMPT"
  | "STATE_CHANGE"
  | "ADVISOR_MORTGAGES_ASSIGNED"
  | "ADVISOR_PROTECTION_ASSIGNED"
  | "APPOINTMENT_ARRANGED"
  | "APPOINTMENT_CHANGED"
  | "APPOINTMENT_CANCELED"
  | "REQUEST_FACT_FIND"
  | "NOTE_ADDED"
  | "DOCUMENT_ADDED"
  | "DOCUMENT_DELETED"
  | "DOCUMENT_REQUESTED"
  | "DOCUMENT_PROVIDED"
  | "DOCUMENT_REQUEST_CANCELED"
  | "MORTGAGE_CREATED"
  | "MORTGAGE_EDITED"
  | "MORTGAGE_NOT_PROCEEDING"
  | "REMORTGAGE_CREATED"
  | "MORTGAGE_CLIENT_TYPE_SWITCHED"
  | "APPLICATION_SUBMITTED"
  | "APPLICATION_STATE_CHANGED"
  | "FACTFIND_TAC_ACCEPTED"
  | "FACTFIND_APPLICANT_DELETED"
  | "FACTFIND_SIGNED_ALL"
  | "FACTFIND_SIGNED"
  | "FACTFIND_UNSIGNED"
  | "LEAD_APPLICANT_CHANGED"
  | "CLIENT_UPDATED"
  | "CASE_PROGRESSION_ASSIGNED"
  | "TASK_CREATED"
  | "TASK_EDITED"
  | "TASK_REASSIGNED"
  | "TASK_COMPLETED"
  | "TASK_DELETED"
  | "FACTFIND_RETURNED"
  | "FACTFIND_APPROVED"
  | "FACTFIND_REACTIVATED"
  | "FACTFIND_SECTION_RETURNED"
  | "FACTFIND_SECTION_CANCEL_RETURN"
  | "COMPLIANCE_HANDOVER_CREATED"
  | "COMPLIANCE_HANDOVER_EDITED"
  | "COMPLIANCE_HANDOVER_APPROVED"
  | "COMPLIANCE_HANDOVER_UNDO_APPROVAL"
  | "INTERESTED_PARTY_ADDED"
  | "INTERESTED_PARTY_EDITED"
  | "INTERESTED_PARTY_DELETED"
  | "INTERESTED_PARTY_UNSUBSCRIBED"
  | "INTRODUCER_UNLINKED"
  | "INTRODUCER_RELINKED"
  | "CLIENT_REMINDER_UNSUBSCRIBED"
  | "SUBAPPLICANT_UNSUBSCRIBED"
  | "SUBAPPLICANT_NOTIFICATIONS_OFF"
  | "SUBAPPLICANT_NOTIFICATIONS_ON"
  | "SALES_ADVISOR_CHANGED"
  | "LENDER_REFERENCE_CHANGED"
  | "PROTECTION_CASE_CREATED"
  | "PROTECTION_CASE_CREATED_FROM_MORTGAGE"
  | "PROTECTION_APPLICATION_CREATED"
  | "PROTECTION_APPLICATION_EDITED"
  | "PROTECTION_APPLICATION_DELETED"
  | "PROTECTION_APPLICATION_UNLIKED"
  | "PROTECTION_SOLUTION_LIKED"
  | "PROTECTION_APPLICANT_PREREQUISITES_ADDED"
  | "PROTECTION_APPLICANT_PREREQUISITES_EDITED"
  | "PROTECTION_APPLICANT_DELETED"
  | "PROTECTION_APPLICATION_STATE_CHANGE"
  | "PROTECTION_HANDOVER_BENEFIT_CREATED"
  | "PROTECTION_HANDOVER_BENEFIT_EDITED"
  | "PROTECTION_HANDOVER_SAVED"
  | "PROTECTION_HANDOVER_APPROVED"
  | "PROTECTION_HANDOVER_UNDO_APPROVAL"
  | "PROTECTION_HANDOVER_CATEGORY_SWITCHED"
  | "PROTECTION_PROVIDER_REFERENCE_CHANGED"
  | "SIGNATURE_REQUEST_SIGNED"
  | "EMAIL_SENT"
  | "EMAIL_RECEIVED"

export const JOURNAL_ACTIVITY_NAME = new Map<JOURNAL_ACTIVITY, string>([
  ["CONTACT_ATTEMPT", "Contact attempted"],
  ["STATE_CHANGE", "State changed"],
  ["ADVISOR_MORTGAGES_ASSIGNED", "Advisor assigned"],
  ["ADVISOR_PROTECTION_ASSIGNED", "Advisor assigned"],
  ["APPOINTMENT_ARRANGED", "Appointment arranged"],
  ["APPOINTMENT_CHANGED", "Appointment changed"],
  ["APPOINTMENT_CANCELED", "Appointment canceled"],
  ["REQUEST_FACT_FIND", "Fact Find requested"],
  ["NOTE_ADDED", "Note added"],
  ["DOCUMENT_ADDED", "Document added"],
  ["DOCUMENT_DELETED", "Document deleted"],
  ["DOCUMENT_REQUESTED", "Document requested"],
  ["DOCUMENT_PROVIDED", "Document provided"],
  ["DOCUMENT_REQUEST_CANCELED", "Document request canceled"],
  ["MORTGAGE_CREATED", "Mortgage created"],
  ["MORTGAGE_EDITED", "Mortgage edited"],
  ["MORTGAGE_CLIENT_TYPE_SWITCHED", "Client type switched"],
  ["REMORTGAGE_CREATED", "Remortgage created"],
  ["MORTGAGE_NOT_PROCEEDING", "Mortgage not proceeding"],
  ["APPLICATION_SUBMITTED", "Application was submitted"],
  ["APPLICATION_STATE_CHANGED", "Application state changed"],
  ["FACTFIND_TAC_ACCEPTED", "Terms and Conditions were accepted"],
  ["FACTFIND_APPLICANT_DELETED", "Applicant removed"],
  ["FACTFIND_SIGNED_ALL", "Factfind signed all"],
  ["FACTFIND_SIGNED", "Factfind signed"],
  ["FACTFIND_UNSIGNED", "Factfind un-signed"],
  ["LEAD_APPLICANT_CHANGED", "Lead Applicant has been changed"],
  ["CLIENT_UPDATED", "Client updated"],
  ["CASE_PROGRESSION_ASSIGNED", "Case Progression Assigned"],
  ["TASK_CREATED", "Task created"],
  ["TASK_EDITED", "Task edited"],
  ["TASK_REASSIGNED", "Task reassigned"],
  ["TASK_COMPLETED", "Task completed"],
  ["TASK_DELETED", "Task deleted"],
  ["FACTFIND_RETURNED", "Fact find returned"],
  ["FACTFIND_APPROVED", "Fact find approved"],
  ["FACTFIND_REACTIVATED", "Fact find reactivated"],
  ["FACTFIND_SECTION_RETURNED", "Section of the fact find returned"],
  ["FACTFIND_SECTION_CANCEL_RETURN", "Return of the fact find section was undone"],
  ["COMPLIANCE_HANDOVER_CREATED", "Compliance handover created"],
  ["COMPLIANCE_HANDOVER_EDITED", "Compliance handover edited"],
  ["COMPLIANCE_HANDOVER_APPROVED", "Compliance handover approved"],
  ["COMPLIANCE_HANDOVER_UNDO_APPROVAL", "Compliance handover approval undone"],
  ["INTERESTED_PARTY_ADDED", "Interested party added"],
  ["INTERESTED_PARTY_EDITED", "Interested party edited"],
  ["INTERESTED_PARTY_DELETED", "Interested party deleted"],
  ["INTERESTED_PARTY_UNSUBSCRIBED", "Interested party unsubscribed"],
  ["INTRODUCER_UNLINKED", "Introducer unlined"],
  ["INTRODUCER_RELINKED", "Introducer relinked"],
  ["CLIENT_REMINDER_UNSUBSCRIBED", "Client reminder unsubscribed"],
  ["SUBAPPLICANT_UNSUBSCRIBED", "Subapplicant unsubscribed"],
  ["SUBAPPLICANT_NOTIFICATIONS_OFF", "Subapplicant notifications turned off"],
  ["SUBAPPLICANT_NOTIFICATIONS_ON", "Subapplicant notifications turned on"],
  ["SALES_ADVISOR_CHANGED", "Sales Advisor changed"],
  ["LENDER_REFERENCE_CHANGED", "Lender reference changed"],
  ["PROTECTION_CASE_CREATED", "Protection case created"],
  ["PROTECTION_CASE_CREATED_FROM_MORTGAGE", "Protection case created from mortgage"],
  ["PROTECTION_APPLICATION_CREATED", "Manual protection application created"],
  ["PROTECTION_APPLICATION_EDITED", "Manual protection application edited"],
  ["PROTECTION_APPLICATION_DELETED", "Protection application deleted"],
  ["PROTECTION_APPLICATION_UNLIKED", "Application unliked in SolutionBuilder"],
  ["PROTECTION_SOLUTION_LIKED", "Solution liked in SolutionBuilder"],
  ["PROTECTION_APPLICANT_PREREQUISITES_ADDED", "Applicant prerequisites added"],
  ["PROTECTION_APPLICANT_PREREQUISITES_EDITED", "Applicant prerequisites edited"],
  ["PROTECTION_APPLICANT_DELETED", "Protection applicant deleted"],
  ["PROTECTION_APPLICATION_STATE_CHANGE", "State of the protection application changed"],
  ["PROTECTION_HANDOVER_BENEFIT_CREATED", "Benefit handover created"],
  ["PROTECTION_HANDOVER_BENEFIT_EDITED", "Benefit handover edited"],
  ["PROTECTION_HANDOVER_SAVED", "Protection handover saved"],
  ["PROTECTION_HANDOVER_APPROVED", "Protection compliance handover approved"],
  ["PROTECTION_HANDOVER_UNDO_APPROVAL", "Protection compliance handover approval undone"],
  ["PROTECTION_HANDOVER_CATEGORY_SWITCHED", "Protection benefit category switched"],
  ["PROTECTION_PROVIDER_REFERENCE_CHANGED", "Provider reference changed"],
  ["SIGNATURE_REQUEST_SIGNED", "Signature request signed"],
  ["EMAIL_SENT", "Email sent"],
  ["EMAIL_RECEIVED", "Email received"],

]);

export const JOURNAL_ACTIVITY_WHITE = new Set<JOURNAL_ACTIVITY>([
  "TASK_CREATED",
  "TASK_COMPLETED",
  "NOTE_ADDED",
  "CONTACT_ATTEMPT",
  "APPOINTMENT_ARRANGED",
  "APPOINTMENT_CHANGED",
  "APPOINTMENT_CANCELED",
  "EMAIL_SENT",
  "EMAIL_RECEIVED",
]);

export enum APPOINTMENT_TYPES {
  PHONE = 'Phone',
  IN_PERSON = 'In person',
  ZOOM = 'Zoom',
  TEAMS = 'Teams',
}

export enum LOCATION_TYPES {
  OFFICE = 'Office',
  OTHER = 'Other'
}

export type MORTGAGE_DOCUMENT_TYPE =
  | "ACCOUNTS"
  | "AFFORDABILITY_CALCULATOR"
  | "APPLICATION"
  | "APPOINTMENT_ATTACHMENT"
  | "BUILDING_CONTENTS_POLICY"
  | "CASE_REPORT"
  | "CURRENT_CONTRACT"
  | "CV"
  | "DECLARATION"
  | "DISCLAIMER"
  | "EXPERIAN_AFP"
  | "EXPERIAN_AML"
  | "EXPERIAN_CREDIT_SEARCH"
  | "GIFTED_DEPOSIT_LETTER"
  | "HTC_SO_CALCULATOR"
  | "ID"
  | "INITIAL_OVERVIEW"
  | "KFI"
  | "LIFE_INSURANCE_POLICY"
  | "MEDICAL_RECORD_ACCESS"
  | "MORTGAGE_OFFER"
  | "OTHER"
  | "P60"
  | "PAYSLIPS"
  | "PORTFOLIO_SCHEDULE"
  | "PREVIOUS_CONTRACT"
  | "PRODUCT_TRANSFER_DECLARATION"
  | "PROOF_OF_DEPOSIT"
  | "RECOMMENDATION_EVIDENCE"
  | "RECORD_OF_SUITABILITY"
  | "SA302"
  | "SALARY_BANK_STATEMENT"
  | "SMARTSEARCH_REPORT"
  | "TAX_YEAR_OVERVIEW"
  | "UTILITY_BILL"
  | "VALUATION_APPEAL_FORM"

export const MORTGAGE_DOCUMENT_TYPE_NAME = new Map<MORTGAGE_DOCUMENT_TYPE, string>([
  ["AFFORDABILITY_CALCULATOR", "Affordability Calculator"],
  ["EXPERIAN_AML", "AML Report"],
  ["APPLICATION", "Application"],
  ["APPOINTMENT_ATTACHMENT", "Appointment Attachment"],
  ["SALARY_BANK_STATEMENT", "Bank Statement"],
  ["BUILDING_CONTENTS_POLICY", "Building Contents Policy"],
  ["CASE_REPORT", "Case Report"],
  ["ACCOUNTS", "Company Accounts"],
  ["CURRENT_CONTRACT", "Contractor Contract - Current"],
  ["PREVIOUS_CONTRACT", "Contractor Contract - Previous"],
  ["EXPERIAN_CREDIT_SEARCH", "Credit Search Report"],
  ["CV", "Curriculum Vitae"],
  ["DECLARATION", "Declaration"],
  ["DISCLAIMER", "Disclaimer"],
  ["RECOMMENDATION_EVIDENCE", "Evidence of recommendation"],
  ["EXPERIAN_AFP", "Experian Castlight Report"],
  ["GIFTED_DEPOSIT_LETTER", "Gifted Deposit Letter"],
  ["HTC_SO_CALCULATOR", "HTB/SO Calculator"],
  ["ID", "Identity Document"],
  ["INITIAL_OVERVIEW", "Initial Overview"],
  ["KFI", "Key Facts Illustration"],
  ["LIFE_INSURANCE_POLICY", "Life Insurance Policy"],
  ["MEDICAL_RECORD_ACCESS", "Medical Record Access"],
  ["MORTGAGE_OFFER", "Mortgage Offer"],
  ["P60", "P60"],
  ["PAYSLIPS", "Payslips"],
  ["PORTFOLIO_SCHEDULE", "Portfolio Schedule"],
  ["PROOF_OF_DEPOSIT", "Proof of Deposit"],
  ["PRODUCT_TRANSFER_DECLARATION", "PT Declaration"],
  ["RECORD_OF_SUITABILITY", "Record of Suitability"],
  ["SA302", "SA302"],
  ["SMARTSEARCH_REPORT", "SmartSearch report"],
  ["TAX_YEAR_OVERVIEW", "Tax Year Overview"],
  ["UTILITY_BILL", "Utility Bill"],
  ["VALUATION_APPEAL_FORM", "Valuation Appeal Form"],

  ["OTHER", "Other"],
]);

export type PROTECTION_DOCUMENT_TYPE =
  | "DECLARATION_OF_HEALTH"
  | "APPOINTMENT_BOOKING"
  | "AMRA"
  | "GP_REPORT"
  | "POLICY"
  | "QUOTE"
  | "APPLICATION_FORM"
  | "PROTECTION_ILLUSTRATION"
  | "COMPARISON_REPORT"
  | "TERMS"
  | "KEY_FEATURES"
  | "OTHER"

export const PROTECTION_DOCUMENT_TYPE_NAME = new Map<PROTECTION_DOCUMENT_TYPE, string>([
  ["DECLARATION_OF_HEALTH", "Declaration of Health"],
  ["APPOINTMENT_BOOKING", "Appointment Booking"],
  ["AMRA", "AMRA (Medical Report Access)"],
  ["GP_REPORT", "GP Report"],
  ["POLICY", "Policy"],
  ["QUOTE", "Quote"],
  ["APPLICATION_FORM", "Application Form"],
  ["TERMS", "Terms"],
  ["KEY_FEATURES", "Key Features"],
  ["PROTECTION_ILLUSTRATION", "Application Illustration"],
  ["COMPARISON_REPORT", "Comparison Report"],
  ["OTHER", "Other"],
]);

export type GENERAL_DOCUMENT_TYPE = MORTGAGE_DOCUMENT_TYPE | PROTECTION_DOCUMENT_TYPE;

export const GENERAL_DOCUMENT_TYPE_NAME = new Map<GENERAL_DOCUMENT_TYPE, string>([...MORTGAGE_DOCUMENT_TYPE_NAME, ...PROTECTION_DOCUMENT_TYPE_NAME]);

export type DOCUMENT_REQUEST_STATUS =
  | "REQUESTED"
  | "SENT_TO_CLIENT"
  | "NOT_SENT_TO_CLIENT"
  | "PROVIDED";

export const DOCUMENT_REQUEST_STATUS_NAME = new Map<DOCUMENT_REQUEST_STATUS, string>([
  ["REQUESTED", "Requested"],
  ["SENT_TO_CLIENT", "Sent to Client"],
  ["NOT_SENT_TO_CLIENT", "Not Sent to Client"],
  ["PROVIDED", "Received from Client"],
]);

export enum PRODUCT_ROLE {
  LEAD = "Lead",
  SUB = "Sub Applicant"
}

export enum PRODUCT_TYPE {
  MORTGAGE = "Mortgage",
  PROTECTION = "Protection"
}

export type HELP_TO_BUY_STATUS =
  | "PIF_SUBMITTED"
  | "AMENDMENT_SUBMITTED"
  | "ATP_ISSUED"
  | "CANCEL";

export const HELP_TO_BUY_STATUS_NAMES = new Map<HELP_TO_BUY_STATUS, string>([
  ["PIF_SUBMITTED", "PIF Submitted"],
  ["AMENDMENT_SUBMITTED", "Amendment submitted"],
  ["ATP_ISSUED", "ATP Issued"],
  ["CANCEL", "Cancel Help to Buy"],
]);

export type ValuationState =
  | "INSTRUCTED" 
  | "BOOKED" 
  | "RECEIVED";

export const ValuationStateName = new Map<ValuationState, string>([
  ["INSTRUCTED", "Instructed"],
  ["BOOKED", "Booked"], 
  ["RECEIVED", "Received"],
]);

export type OFFER_QUALIFICATION_STATUS =
  | "RE_QUALIFICATION_REQUESTED"
  | "QUALIFIED"
  | "PARTIALLY_QUALIFIED"
  | "NOT_QUALIFIED"

export const OFFER_QUALIFICATION_STATUS_NAME = new Map<OFFER_QUALIFICATION_STATUS, string>([
  ["RE_QUALIFICATION_REQUESTED", "RE_QUALIFICATION_REQUESTED"],
  ["QUALIFIED", "QUALIFIED"],
  ["PARTIALLY_QUALIFIED", "PARTIALLY_QUALIFIED"],
  ["NOT_QUALIFIED", "NOT_QUALIFIED"],
])

export type COMPLIANCE_HANDOVER_STATE =
  | "AWAITING_APPROVAL"
  | "APPROVED"
  | "DRAFT"

export const COMPLIANCE_HANDOVER_STATE_NAME = new Map<COMPLIANCE_HANDOVER_STATE, string>([
  ["AWAITING_APPROVAL", "Awaiting Approval"],
  ["APPROVED", "Approved"],
  ["DRAFT", "Draft"],
])

export type MORTGAGE_OBJECTIVE =
  | "FTB"
  | "RESI_RE"
  | "RESI_RE_CR"
  | "BTL"
  | "BTL_RE"
  | "BTL_RE_CR"
  | "RESI_PT"
  | "BTL_PT"
  | "PORT"
  | "FA"
  | "PORT_FA"
  | "LET_TO_BUY"
  | "LET_TO_BUY_CR"
  | "OTHER"

export const MORTGAGE_OBJECTIVE_NAME = new Map<MORTGAGE_OBJECTIVE, string>([
  ["FTB", "Residential Purchase"],
  ["RESI_RE", "Residential Remortgage"],
  ["BTL", "Buy To Let Purchase"],
  ["RESI_RE_CR", "Residential Remortgage + Cap Raising"],
  ["BTL_RE", "Buy to Let Remortgage"],
  ["BTL_RE_CR", "Buy to Let Remortgage + Cap Raising"],
  ["RESI_PT", "Residential Product Transfer"],
  ["BTL_PT", "Buy to Let Product Transfer"],
  ["PORT", "Port"],
  ["FA", "Further Advance"],
  ["PORT_FA", "Port + Further Advance"],
  ["LET_TO_BUY", "Let to Buy"],
  ["LET_TO_BUY_CR", "Let to Buy + Cap Raising"],
  ["OTHER", "Other"],
])

export type DEBT_CONSOLIDATION_EXPLANATION =
  | "REDUCE_OUTGOINGS"
  | "OTHER"

export const DEBT_CONSOLIDATION_EXPLANATION_NAME = new Map<DEBT_CONSOLIDATION_EXPLANATION, string>([
  ["REDUCE_OUTGOINGS", "To reduce monthly outgoings"],
  ["OTHER", "Other"],
])

export type MORTGAGE_PRODUCT_DATA_TYPE =
  | "FIXED"
  | "TRACKER"
  | "DISCOUNT"
  | "CAPPED"
  | "VARIABLE"

export const MORTGAGE_PRODUCT_DATA_TYPE_NAME = new Map<MORTGAGE_PRODUCT_DATA_TYPE, string>([
  ["FIXED", "Fixed"],
  ["TRACKER", "Tracker"],
  ["DISCOUNT", "Discount"],
  ["CAPPED", "Capped"],
  ["VARIABLE", "Variable"],
])

export type MORTGAGE_PRODUCT_END_TYPE	=
  | "DATE"
  | "YEARS_MONTHS"
  | "LIFETIME"

export const MORTGAGE_PRODUCT_END_TYPE_NAME_ALL = new Map<MORTGAGE_PRODUCT_END_TYPE, string>([
  ["DATE", "Select Date"],
  ["YEARS_MONTHS", "Years & Months"],
  ["LIFETIME", "Lifetime"],
])

export const MORTGAGE_PRODUCT_END_TYPE_NAME = new Map<MORTGAGE_PRODUCT_END_TYPE, string>([
  ["DATE", "Select Date"],
  ["YEARS_MONTHS", "Years & Months"],
])

export type TASK_LIST_TYPE =
  | "MORTGAGE"
  | "MY"
  | "TEAM"
  | "DELEGATED";

export type INTERESTED_PARTY_TYPE =
  | "INTERESTED_PARTY"
  | "HOUSE_BUILDER"
  | "ESTATE_AGENT"

export const INTERESTED_PARTY_TYPE_NAME = new Map<INTERESTED_PARTY_TYPE, string>([
  ["INTERESTED_PARTY", "Interested Party"],
  ["HOUSE_BUILDER", "House Builder"],
  ["ESTATE_AGENT", "Estate Agent "],
])

export type NOTIFICATION_PARTY_TYPE =
  | "LEAD_APPLICANT"
  | "SUB_APPLICANT"
  | "INTRODUCER"
  | "SOLICITOR"
  | "INTERESTED_PARTY"
  | "INTERESTED_PARTY_ESTATE_AGENT"
  | "INTERESTED_PARTY_HOUSE_BUILDER"

export const NOTIFICATION_PARTY_TYPE_NAME = new Map<NOTIFICATION_PARTY_TYPE, string>([
  ["LEAD_APPLICANT", "Lead Applicant"],
  ["SUB_APPLICANT", "Sub Applicant"],
  ["INTRODUCER", "Introducer"],
  ["SOLICITOR", "Solicitor"],
  ["INTERESTED_PARTY", "Interested Party"],
  ["INTERESTED_PARTY_ESTATE_AGENT", "Estate Agent"],
  ["INTERESTED_PARTY_HOUSE_BUILDER", "House Builder"],
])

export type PROCEEDING_STATE_TYPE =
  | "WAITING"
  | "PROCEEDING"
  | "NOT_PROCEEDING"

export const PROCEEDING_STATE_TYPE_NAME = new Map<PROCEEDING_STATE_TYPE, string>([
  ["WAITING", "Waiting"],
  ["PROCEEDING", "Proceeding"],
  ["NOT_PROCEEDING", "Not Proceeding"],
])

export type PROTECTION_CASE_STATE =
  | "AWAITING_FIRST_CONTACT"
  | "CONTACT_ATTEMPTED"
  | "IN_PROGRESS"
  | "PREPARED"
  | "SUBMITTED"
  | "COMPLETED"
  | "NOT_PROCEEDING"
  | "DELETED"

export type PROTECTION_ACTION =
  | "ATTEMPT_CONTACT"
  | "ARRANGE_APPOINTMENT"
  | "APPOINTMENT"
  | "CLIENT_REGISTRATION"
  | "REQUEST_FACT_FIND"
  | "COMPLETE_FACT_FIND"
  | "SIGN_FACT_FIND"
  | "REVIEW_FACT_FIND"
  | "CREATE_APPLICATIONS"
  | "COMPLIANCE_HANDOVER"
  | "COMPLIANCE_APPROVAL"
  | "APPLICATION_SUBMISSION"
  | "APPLICATION_COMPLETION"
  | "UNABLE_TO_DETERMINE"
  | "NONE"

export type PROTECTION_WAITING_ON =
  | "ADVISOR"
  | "COMPLIANCE"
  | "APPLICATION"
  | "CLIENT"
  | "NONE"

export type PROTECTION_PROCEEDING_STATE =
  | "WAITING"
  | "PROCEEDING"
  | "NOT_PROCEEDING"

export type PROTECTION_CASE_QUICK_FILTER =
  | "ACTIVE"
  | "ASSIGNED"
  | "ARCHIVE"

export type ProtectionApplicationState =
  | "NOT_SUBMITTED"
  | "REFERRED"
  | "ACCEPTED"
  | "ACCEPTED_NON_STANDARD"
  | "DECLINED"
  | "UNDERWRITING"
  | "ON_RISK"
  | "NOT_PROCEEDING"
  | "DELETED"

export const ProtectionApplicationStateName = new Map<ProtectionApplicationState, string>([
  ["NOT_SUBMITTED", "Not submitted"],                   // yellow
  ["REFERRED", "Referred"],
  ["ACCEPTED", "Accepted"],                             // green
  ["ACCEPTED_NON_STANDARD", "Accepted non-standard"],   // green
  ["DECLINED", "Declined"],
  ["UNDERWRITING", "In underwriting"],
  ["ON_RISK", "On risk"],
  ["NOT_PROCEEDING", "Not proceeding"],
  ["DELETED", "Deleted"],                               // not visible
])

export const SidepaneProtectionApplicationStateName = new Map(
  [...ProtectionApplicationStateName.entries()]
  .filter(entry => !["NOT_SUBMITTED", "DELETED"].includes(entry[0]))
);

export type ProtectionApplicationSubState	=
  | "MEDICAL_REQUESTED"
  | "GP_REPORT_REQUESTED"
  | "FURTHER_MEDICAL_REQUESTED"

export const ProtectionApplicationSubStateName = new Map<ProtectionApplicationSubState, string>([
  ["MEDICAL_REQUESTED", "Medical Requested"],
  ["GP_REPORT_REQUESTED", "GP Report Requested"],
  ["FURTHER_MEDICAL_REQUESTED", "Further Medical Evidence Requested"],
])

export type BenefitHandoverState =
  | "INCOMPLETE"
  | "COMPLETE"

export const BenefitHandoverStateName = new Map<BenefitHandoverState, string>([
  ["INCOMPLETE", "Incomplete"],
  ["COMPLETE", "Complete"],
])

export type HandoverSection =
  | "CIRCUMSTANCES_AND_OBJECTIVES"
  | "PREFERENCES"
  | "BUDGET_AND_AFFORDABILITY"
  | "RECOMMENDED_MORTGAGE"

export const HandoverSectionName = new Map<HandoverSection, string>([
  ["CIRCUMSTANCES_AND_OBJECTIVES", "Circumstances and Objectives"],
  ["PREFERENCES", "Preferences"],
  ["BUDGET_AND_AFFORDABILITY", "Budget and Affordability"],
  ["RECOMMENDED_MORTGAGE", "Recommended Mortgage"],
]);

export type HandoverSectionState = 
  | "LEGACY"
  | "NOT_STARTED"
  | "STARTED"
  | "COMPLETED"

export type RemortgageReason = 
  | "AVOID_STANDARD_RATE"
  | "ACHIEVE_COMPETITIVE_RATE"
  | "RAISE_CAPITAL"
  | "REDUCE_MORTGAGE_BALANCE"
  | "OTHER"

export const RemortgageReasonName = new Map<RemortgageReason, string>([
  ["AVOID_STANDARD_RATE", "Avoid the lender's standard variable rate"],
  ["ACHIEVE_COMPETITIVE_RATE", "Achieve a more competitive interest rate"],
  ["RAISE_CAPITAL", "Raise capital"],
  ["REDUCE_MORTGAGE_BALANCE", "Reduce the mortgage balance"],
  ["OTHER", "Other"],
])

export type MortgageDebtConsolidationReason =
  | "REDUCE_OVERALL_COSTS"
  | "REDUCE_MONTHLY_PAYMENTS"
  | "REDUCE_MONTHLY_EXPEDITURE"
  | "REDUCE_INTEREST"
  | "OTHER"

export const MortgageDebtConsolidationReasonName = new Map<MortgageDebtConsolidationReason, string>([
  ["REDUCE_OVERALL_COSTS", "Reduce the overall costs of repaying this over the long term"],
  ["REDUCE_MONTHLY_PAYMENTS", "Reduce your monthly payments"],
  ["REDUCE_MONTHLY_EXPEDITURE", "Reduce your monthly expediture"],
  ["REDUCE_INTEREST", "Reduce the interest rates that you are paying"],
  ["OTHER", "Other"],
])

export type MortgageOffsetReason =
  | "EXISTING_SAVINGS"
  | "FUTURE_SAVINGS"
  | "FUTURE_LUMP"

export const MortgageOffsetReasonName = new Map<MortgageOffsetReason, string>([
  ["EXISTING_SAVINGS", "Existing savings"],
  ["FUTURE_SAVINGS", "Future savings"],
  ["FUTURE_LUMP", "Future lump sum"],
])

export type MortgageProductTerm = 
  | "NO_PREFERENCES"
  | "TWO_YEARS"
  | "THREE_YEARS"
  | "FIVE_YEARS"
  | "TEN_YEARS"

export const MortgageProductTermName = new Map<MortgageProductTerm, string>([
  ["NO_PREFERENCES", "No preference"],
  ["TWO_YEARS", "2 years"],
  ["THREE_YEARS", "3 years"],
  ["FIVE_YEARS", "5 years"],
  ["TEN_YEARS", "10 years"],
])

export type FeesToLoanReason = 
  | "REDUCE_UPFRONT_COSTS"
  | "INSUFFICIENT_FUNDS_AVAILABLE"
  | "REPAY_AFTER_COMPLETION"
  | "OTHER"

export const FeesToLoanReasonName = new Map<FeesToLoanReason, string>([
  ["REDUCE_UPFRONT_COSTS", "Reduce upfront costs"],
  ["INSUFFICIENT_FUNDS_AVAILABLE", "Insufficient funds available"],
  ["REPAY_AFTER_COMPLETION", "Repay after completion"],
  ["OTHER", "Other"],
])

export type EditChangesType =
    | "CLIENT_SHEET"
    | "CLIENT_SHEET_OBJECTIVE"
    | "CLIENT_SHEET_PREFERENCE"
    | "CLIENT_SHEET_BUDGET"
    | "CLIENT_SHEET_RECOMMENDED"
    | "FACT_FIND"

export const EditChangesTypeName = new Map<EditChangesType, string>([
    ["CLIENT_SHEET", ""],
    ["CLIENT_SHEET_OBJECTIVE", "Objectives"],
    ["CLIENT_SHEET_PREFERENCE", "Preferences"],
    ["CLIENT_SHEET_BUDGET", "Budget"],
    ["CLIENT_SHEET_RECOMMENDED", "Recommended"],
    ["FACT_FIND", ""],
]);

export interface PhonePrefix {
  id: number;
  country: string;
  prefix: string;
}

export const PHONE_PREFIXES: PhonePrefix[] = [
  { id: 284, country: 'United Kingdom', prefix: '+44' },
  { id: 1, country: 'Afghanistan', prefix: '+93' },
  { id: 2, country: 'Albania', prefix: '+355' },
  { id: 3, country: 'Algeria', prefix: '+213' },
  { id: 4, country: 'American Samoa', prefix: '+1 684' },
  { id: 5, country: 'Andorra', prefix: '+376' },
  { id: 6, country: 'Angola', prefix: '+244' },
  { id: 7, country: 'Anguilla', prefix: '+1 264' },
  { id: 8, country: 'Antigua and Barbuda', prefix: '+1 268' },
  { id: 9, country: 'Argentina', prefix: '+54' },
  { id: 10, country: 'Armenia', prefix: '+374' },
  { id: 11, country: 'Aruba', prefix: '+297' },
  { id: 12, country: 'Ascension', prefix: '+247' },
  { id: 13, country: 'Australia', prefix: '+61' },
  { id: 14, country: 'Australian Antarctic Territory', prefix: '+672 1' },
  { id: 15, country: 'Australian External Territories', prefix: '+672' },
  { id: 16, country: 'Austria', prefix: '+43' },
  { id: 17, country: 'Azerbaijan', prefix: '+994' },
  { id: 18, country: 'Bahamas', prefix: '+1 242' },
  { id: 19, country: 'Bahrain', prefix: '+973' },
  { id: 20, country: 'Bangladesh', prefix: '+880' },
  { id: 21, country: 'Barbados', prefix: '+1 246' },
  { id: 22, country: 'Barbuda', prefix: '+1 268' },
  { id: 23, country: 'Belarus', prefix: '+375' },
  { id: 24, country: 'Belgium', prefix: '+32' },
  { id: 25, country: 'Belize', prefix: '+501' },
  { id: 26, country: 'Benin', prefix: '+229' },
  { id: 27, country: 'Bermuda', prefix: '+1 441' },
  { id: 28, country: 'Bhutan', prefix: '+975' },
  { id: 29, country: 'Bolivia', prefix: '+591' },
  { id: 30, country: 'Bonaire', prefix: '+599 7' },
  { id: 31, country: 'Bosnia and Herzegovina', prefix: '+387' },
  { id: 32, country: 'Botswana', prefix: '+267' },
  { id: 33, country: 'Brazil', prefix: '+55' },
  { id: 34, country: 'British Indian Ocean Territory', prefix: '+246' },
  { id: 35, country: 'British Virgin Islands', prefix: '+1 284' },
  { id: 36, country: 'Brunei Darussalam', prefix: '+673' },
  { id: 37, country: 'Bulgaria', prefix: '+359' },
  { id: 38, country: 'Burkina Faso', prefix: '+226' },
  { id: 39, country: 'Burundi', prefix: '+257' },
  { id: 40, country: 'Cabo Verde', prefix: '+238' },
  { id: 41, country: 'Cambodia', prefix: '+855' },
  { id: 42, country: 'Cameroon', prefix: '+237' },
  { id: 43, country: 'Canada', prefix: '+1' },
  { id: 44, country: 'Caribbean Netherlands', prefix: '+599 3' },
  { id: 45, country: 'Caribbean Netherlands', prefix: '+599 4' },
  { id: 46, country: 'Caribbean Netherlands', prefix: '+599 7' },
  { id: 47, country: 'Cayman Islands', prefix: '+1 345' },
  { id: 48, country: 'Central African Republic', prefix: '+236' },
  { id: 49, country: 'Chad', prefix: '+235' },
  { id: 50, country: 'Chatham Island, New Zealand', prefix: '+64' },
  { id: 51, country: 'Chile', prefix: '+56' },
  { id: 52, country: 'China', prefix: '+86' },
  { id: 53, country: 'Christmas Island', prefix: '+61 89164' },
  { id: 54, country: 'Cocos (Keeling) Islands', prefix: '+61 89162' },
  { id: 55, country: 'Colombia', prefix: '+57' },
  { id: 56, country: 'Comoros', prefix: '+269' },
  { id: 57, country: 'Congo', prefix: '+242' },
  { id: 58, country: 'Congo, Democratic Republic of the (Zaire)', prefix: '+243' },
  { id: 59, country: 'Cook Islands', prefix: '+682' },
  { id: 60, country: 'Costa Rica', prefix: '+506' },
  { id: 61, country: 'Croatia', prefix: '+385' },
  { id: 62, country: 'Cuba', prefix: '+53' },
  { id: 63, country: 'Curaçao', prefix: '+599 9' },
  { id: 64, country: 'Cyprus', prefix: '+357' },
  { id: 65, country: 'Czech Republic', prefix: '+420' },
  { id: 66, country: 'Denmark', prefix: '+45' },
  { id: 67, country: 'Diego Garcia', prefix: '+246' },
  { id: 68, country: 'Djibouti', prefix: '+253' },
  { id: 69, country: 'Dominica', prefix: '+1 767' },
  { id: 70, country: 'Dominican Republic', prefix: '+1 809' },
  { id: 71, country: 'Dominican Republic', prefix: '+1 829' },
  { id: 72, country: 'Dominican Republic', prefix: '+1 849' },
  { id: 73, country: 'East Timor', prefix: '+670' },
  { id: 74, country: 'Easter Island', prefix: '+56' },
  { id: 75, country: 'Ecuador', prefix: '+593' },
  { id: 76, country: 'Egypt', prefix: '+20' },
  { id: 77, country: 'El Salvador', prefix: '+503' },
  { id: 78, country: 'Ellipso (Mobile Satellite service)', prefix: '+881 2' },
  { id: 79, country: 'Ellipso (Mobile Satellite service)', prefix: '+881 3' },
  { id: 80, country: 'EMSAT (Mobile Satellite service)', prefix: '+882 13' },
  { id: 81, country: 'Equatorial Guinea', prefix: '+240' },
  { id: 82, country: 'Eritrea', prefix: '+291' },
  { id: 83, country: 'Estonia', prefix: '+372' },
  { id: 84, country: 'Ethiopia', prefix: '+251' },
  { id: 85, country: 'Falkland Islands', prefix: '+500' },
  { id: 86, country: 'Faroe Islands', prefix: '+298' },
  { id: 87, country: 'Fiji', prefix: '+679' },
  { id: 88, country: 'Finland', prefix: '+358' },
  { id: 89, country: 'France', prefix: '+33' },
  { id: 90, country: 'French Antilles', prefix: '+596' },
  { id: 91, country: 'French Guiana', prefix: '+594' },
  { id: 92, country: 'French Polynesia', prefix: '+689' },
  { id: 93, country: 'Gabon', prefix: '+241' },
  { id: 94, country: 'Gambia', prefix: '+220' },
  { id: 95, country: 'Georgia', prefix: '+995' },
  { id: 96, country: 'Germany', prefix: '+49' },
  { id: 97, country: 'Ghana', prefix: '+233' },
  { id: 98, country: 'Gibraltar', prefix: '+350' },
  { id: 99, country: 'Global Mobile Satellite System (GMSS)', prefix: '+881' },
  { id: 100, country: 'Globalstar (Mobile Satellite Service)', prefix: '+881 8' },
  { id: 101, country: 'Globalstar (Mobile Satellite Service)', prefix: '+881 9' },
  { id: 102, country: 'Greece', prefix: '+30' },
  { id: 103, country: 'Greenland', prefix: '+299' },
  { id: 104, country: 'Grenada', prefix: '+1 473' },
  { id: 105, country: 'Guadeloupe', prefix: '+590' },
  { id: 106, country: 'Guam', prefix: '+1 671' },
  { id: 107, country: 'Guatemala', prefix: '+502' },
  { id: 108, country: 'Guernsey', prefix: '+44 1481' },
  { id: 109, country: 'Guernsey', prefix: '+44 7781' },
  { id: 110, country: 'Guernsey', prefix: '+44 7839' },
  { id: 111, country: 'Guernsey', prefix: '+44 7911' },
  { id: 112, country: 'Guinea', prefix: '+224' },
  { id: 113, country: 'Guinea-Bissau', prefix: '+245' },
  { id: 114, country: 'Guyana', prefix: '+592' },
  { id: 115, country: 'Haiti', prefix: '+509' },
  { id: 116, country: 'Honduras', prefix: '+504' },
  { id: 117, country: 'Hong Kong', prefix: '+852' },
  { id: 118, country: 'Hungary', prefix: '+36' },
  { id: 119, country: 'Iceland', prefix: '+354' },
  { id: 120, country: 'ICO Global (Mobile Satellite Service)', prefix: '+881 0' },
  { id: 121, country: 'ICO Global (Mobile Satellite Service)', prefix: '+881 1' },
  { id: 122, country: 'India', prefix: '+91' },
  { id: 123, country: 'Indonesia', prefix: '+62' },
  { id: 124, country: 'Inmarsat SNAC', prefix: '+870' },
  { id: 125, country: 'International Freephone Service (UIFN)', prefix: '+800' },
  { id: 126, country: 'International Networks', prefix: '+882' },
  { id: 127, country: 'International Networks', prefix: '+883' },
  { id: 128, country: 'International Premium Rate Service', prefix: '+979' },
  { id: 129, country: 'International Shared Cost Service (ISCS)', prefix: '+808' },
  { id: 130, country: 'Iran', prefix: '+98' },
  { id: 131, country: 'Iraq', prefix: '+964' },
  { id: 132, country: 'Ireland', prefix: '+353' },
  { id: 133, country: 'Iridium (Mobile Satellite service)', prefix: '+881 6' },
  { id: 134, country: 'Iridium (Mobile Satellite service)', prefix: '+881 7' },
  { id: 135, country: 'Isle of Man', prefix: '+44 1624' },
  { id: 136, country: 'Isle of Man', prefix: '+44 7524' },
  { id: 137, country: 'Isle of Man', prefix: '+44 7624' },
  { id: 138, country: 'Isle of Man', prefix: '+44 7924' },
  { id: 139, country: 'Israel', prefix: '+972' },
  { id: 140, country: 'Italy', prefix: '+39' },
  { id: 141, country: 'Ivory Coast', prefix: '+225' },
  { id: 142, country: 'Jamaica', prefix: '+1 876' },
  { id: 143, country: 'Jan Mayen', prefix: '+47 79' },
  { id: 144, country: 'Japan', prefix: '+81' },
  { id: 145, country: 'Jersey', prefix: '+44 1534' },
  { id: 146, country: 'Jordan', prefix: '+962' },
  { id: 147, country: 'Kazakhstan', prefix: '+7 6' },
  { id: 148, country: 'Kazakhstan', prefix: '+7 7' },
  { id: 149, country: 'Kenya', prefix: '+254' },
  { id: 150, country: 'Kiribati', prefix: '+686' },
  { id: 151, country: 'Korea, North', prefix: '+850' },
  { id: 152, country: 'Korea, South', prefix: '+82' },
  { id: 153, country: 'Kosovo', prefix: '+383' },
  { id: 154, country: 'Kuwait', prefix: '+965' },
  { id: 155, country: 'Kyrgyzstan', prefix: '+996' },
  { id: 156, country: 'Laos', prefix: '+856' },
  { id: 157, country: 'Latvia', prefix: '+371' },
  { id: 158, country: 'Lebanon', prefix: '+961' },
  { id: 159, country: 'Lesotho', prefix: '+266' },
  { id: 160, country: 'Liberia', prefix: '+231' },
  { id: 161, country: 'Libya', prefix: '+218' },
  { id: 162, country: 'Liechtenstein', prefix: '+423' },
  { id: 163, country: 'Lithuania', prefix: '+370' },
  { id: 164, country: 'Luxembourg', prefix: '+352' },
  { id: 165, country: 'Macau', prefix: '+853' },
  { id: 166, country: 'Macedonia', prefix: '+389' },
  { id: 167, country: 'Madagascar', prefix: '+261' },
  { id: 168, country: 'Malawi', prefix: '+265' },
  { id: 169, country: 'Malaysia', prefix: '+60' },
  { id: 170, country: 'Maldives', prefix: '+960' },
  { id: 171, country: 'Mali', prefix: '+223' },
  { id: 172, country: 'Malta', prefix: '+356' },
  { id: 173, country: 'Marshall Islands', prefix: '+692' },
  { id: 174, country: 'Martinique', prefix: '+596' },
  { id: 175, country: 'Mauritania', prefix: '+222' },
  { id: 176, country: 'Mauritius', prefix: '+230' },
  { id: 177, country: 'Mayotte', prefix: '+262 269' },
  { id: 178, country: 'Mayotte', prefix: '+262 639' },
  { id: 179, country: 'Mexico', prefix: '+52' },
  { id: 180, country: 'Micronesia, Federated States of', prefix: '+691' },
  { id: 181, country: 'Midway Island, USA', prefix: '+1 808' },
  { id: 182, country: 'Moldova', prefix: '+373' },
  { id: 183, country: 'Monaco', prefix: '+377' },
  { id: 184, country: 'Mongolia', prefix: '+976' },
  { id: 185, country: 'Montenegro', prefix: '+382' },
  { id: 186, country: 'Montserrat', prefix: '+1 664' },
  { id: 187, country: 'Morocco', prefix: '+212' },
  { id: 188, country: 'Mozambique', prefix: '+258' },
  { id: 189, country: 'Myanmar', prefix: '+95' },
  { id: 190, country: 'Nagorno-Karabakh', prefix: '+374 47' },
  { id: 191, country: 'Nagorno-Karabakh', prefix: '+374 97' },
  { id: 192, country: 'Namibia', prefix: '+264' },
  { id: 193, country: 'Nauru', prefix: '+674' },
  { id: 194, country: 'Nepal', prefix: '+977' },
  { id: 195, country: 'Netherlands', prefix: '+31' },
  { id: 196, country: 'Nevis', prefix: '+1 869' },
  { id: 197, country: 'New Caledonia', prefix: '+687' },
  { id: 198, country: 'New Zealand', prefix: '+64' },
  { id: 199, country: 'Nicaragua', prefix: '+505' },
  { id: 200, country: 'Niger', prefix: '+227' },
  { id: 201, country: 'Nigeria', prefix: '+234' },
  { id: 202, country: 'Niue', prefix: '+683' },
  { id: 203, country: 'Norfolk Island', prefix: '+672 3' },
  { id: 204, country: 'Northern Cyprus', prefix: '+90 392' },
  { id: 205, country: 'Northern Ireland', prefix: '+44 28' },
  { id: 206, country: 'Northern Mariana Islands', prefix: '+1 670' },
  { id: 207, country: 'Norway', prefix: '+47' },
  { id: 208, country: 'Oman', prefix: '+968' },
  { id: 209, country: 'Pakistan', prefix: '+92' },
  { id: 210, country: 'Palau', prefix: '+680' },
  { id: 211, country: 'Palestine, State of', prefix: '+970' },
  { id: 212, country: 'Panama', prefix: '+507' },
  { id: 213, country: 'Papua New Guinea', prefix: '+675' },
  { id: 214, country: 'Paraguay', prefix: '+595' },
  { id: 215, country: 'Peru', prefix: '+51' },
  { id: 216, country: 'Philippines', prefix: '+63' },
  { id: 217, country: 'Pitcairn Islands', prefix: '+64' },
  { id: 218, country: 'Poland', prefix: '+48' },
  { id: 219, country: 'Portugal', prefix: '+351' },
  { id: 220, country: 'Puerto Rico', prefix: '+1 787' },
  { id: 221, country: 'Puerto Rico', prefix: '+1 939' },
  { id: 222, country: 'Qatar', prefix: '+974' },
  { id: 223, country: 'Romania', prefix: '+40' },
  { id: 224, country: 'Russia', prefix: '+7' },
  { id: 225, country: 'Rwanda', prefix: '+250' },
  { id: 226, country: 'Réunion', prefix: '+262' },
  { id: 227, country: 'Saba', prefix: '+599 4' },
  { id: 228, country: 'Saint Barthélemy', prefix: '+590' },
  { id: 229, country: 'Saint Helena', prefix: '+290' },
  { id: 230, country: 'Saint Kitts and Nevis', prefix: '+1 869' },
  { id: 231, country: 'Saint Lucia', prefix: '+1 758' },
  { id: 232, country: 'Saint Martin (France)', prefix: '+590' },
  { id: 233, country: 'Saint Pierre and Miquelon', prefix: '+508' },
  { id: 234, country: 'Saint Vincent and the Grenadines', prefix: '+1 784' },
  { id: 235, country: 'Samoa', prefix: '+685' },
  { id: 236, country: 'San Marino', prefix: '+378' },
  { id: 237, country: 'Saudi Arabia', prefix: '+966' },
  { id: 238, country: 'Senegal', prefix: '+221' },
  { id: 239, country: 'Serbia', prefix: '+381' },
  { id: 240, country: 'Seychelles', prefix: '+248' },
  { id: 241, country: 'Sierra Leone', prefix: '+232' },
  { id: 242, country: 'Singapore', prefix: '+65' },
  { id: 243, country: 'Sint Eustatius', prefix: '+599 3' },
  { id: 244, country: 'Sint Maarten (Netherlands)', prefix: '+1 721' },
  { id: 245, country: 'Slovakia', prefix: '+421' },
  { id: 246, country: 'Slovenia', prefix: '+386' },
  { id: 247, country: 'Solomon Islands', prefix: '+677' },
  { id: 248, country: 'Somalia', prefix: '+252' },
  { id: 249, country: 'South Africa', prefix: '+27' },
  { id: 250, country: 'South Georgia and the South Sandwich Islands', prefix: '+500' },
  { id: 251, country: 'South Ossetia', prefix: '+995 34' },
  { id: 252, country: 'South Sudan', prefix: '+211' },
  { id: 253, country: 'Spain', prefix: '+34' },
  { id: 254, country: 'Sri Lanka', prefix: '+94' },
  { id: 255, country: 'Sudan', prefix: '+249' },
  { id: 256, country: 'Suriname', prefix: '+597' },
  { id: 257, country: 'Svalbard', prefix: '+47 79' },
  { id: 258, country: 'Swaziland', prefix: '+268' },
  { id: 259, country: 'Sweden', prefix: '+46' },
  { id: 260, country: 'Switzerland', prefix: '+41' },
  { id: 261, country: 'Syria', prefix: '+963' },
  { id: 262, country: 'São Tomé and Príncipe', prefix: '+239' },
  { id: 263, country: 'Taiwan', prefix: '+886' },
  { id: 264, country: 'Tajikistan', prefix: '+992' },
  { id: 265, country: 'Tanzania', prefix: '+255' },
  { id: 266, country: 'Telecommunications for Disaster Relief by OCHA', prefix: '+888' },
  { id: 267, country: 'Thailand', prefix: '+66' },
  { id: 268, country: 'Thuraya (Mobile Satellite service)', prefix: '+882 16' },
  { id: 269, country: 'Togo', prefix: '+228' },
  { id: 270, country: 'Tokelau', prefix: '+690' },
  { id: 271, country: 'Tonga', prefix: '+676' },
  { id: 272, country: 'Transnistria', prefix: '+373 2' },
  { id: 273, country: 'Transnistria', prefix: '+373 5' },
  { id: 274, country: 'Trinidad and Tobago', prefix: '+1 868' },
  { id: 275, country: 'Tristan da Cunha', prefix: '+290 8' },
  { id: 276, country: 'Tunisia', prefix: '+216' },
  { id: 277, country: 'Turkey', prefix: '+90' },
  { id: 278, country: 'Turkmenistan', prefix: '+993' },
  { id: 279, country: 'Turks and Caicos Islands', prefix: '+1 649' },
  { id: 280, country: 'Tuvalu', prefix: '+688' },
  { id: 281, country: 'Uganda', prefix: '+256' },
  { id: 282, country: 'Ukraine', prefix: '+380' },
  { id: 283, country: 'United Arab Emirates', prefix: '+971' },
  { id: 285, country: 'United States', prefix: '+1' },
  { id: 286, country: 'Universal Personal Telecommunications (UPT)', prefix: '+878' },
  { id: 287, country: 'Uruguay', prefix: '+598' },
  { id: 288, country: 'US Virgin Islands', prefix: '+1 340' },
  { id: 289, country: 'Uzbekistan', prefix: '+998' },
  { id: 290, country: 'Vanuatu', prefix: '+678' },
  { id: 291, country: 'Vatican City State (Holy See)', prefix: '+39 06 698' },
  { id: 292, country: 'Vatican City State (Holy See) assigned', prefix: '+379' },
  { id: 293, country: 'Venezuela', prefix: '+58' },
  { id: 294, country: 'Vietnam', prefix: '+84' },
  { id: 295, country: 'Wake Island, USA', prefix: '+1 808' },
  { id: 296, country: 'Wallis and Futuna', prefix: '+681' },
  { id: 297, country: 'Yemen', prefix: '+967' },
  { id: 298, country: 'Zambia', prefix: '+260' },
  { id: 299, country: 'Zanzibar', prefix: '+255 24' },
  { id: 300, country: 'Zimbabwe', prefix: '+263' },
  { id: 301, country: 'Åland Islands', prefix: '+358 18' }
];
