import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthTokenProvider } from '../../../../../../../src/app/website/directives/srcset.directive';
import { GENERAL_DOCUMENT_TYPE_NAME } from "../../../../../../../src/app/website/utils/variables.data";
import { FactFindUploadService } from '../../../fact-find/components/fact-find-upload/fact-find-upload.component';
import { Document } from '../../../fact-find/services/fact-find-api.service';
import { NavigationService } from '../../../root/services/navigation.service';
import { User, UserService } from '../../../root/services/user.service';
import { ToastService } from '../../../shared/services/toast.service';
import { DocumentApiService, DocumentRequest, DocumentRequestType } from "../../services/document-api.service";

@Component({
  selector: 'hfc-document-layout',
  templateUrl: './document-layout.component.html',
  styleUrls: ['./document-layout.component.scss'],
  providers: [
    { provide: FactFindUploadService, useExisting: DocumentLayoutComponent },
    { provide: AuthTokenProvider, useExisting: DocumentLayoutComponent },
  ],
})
export class DocumentLayoutComponent implements FactFindUploadService, AuthTokenProvider {
  public form: FormArray;

  public GENERAL_DOCUMENT_TYPE_NAME = GENERAL_DOCUMENT_TYPE_NAME;

  public documentRequest: DocumentRequest;
  public isSubmitting = false;
  public errorMessage: string;

  public get user() { return this.userService.user }
  public get isTokenAuth() { return !this.userService.user }
  public get token() { return this.route.snapshot.paramMap.get("token") }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private userService: UserService,
    private toastService: ToastService,
    private documentApiService: DocumentApiService
  ) {
    this.initialize();
  }

  public getToken(): string {
    return this.token;
  }

  public addDocument(document: Document, file: File): Promise<Document> {
    const documentType: DocumentRequestType = document.type as any;

    return this.documentApiService.provideRequestedDocument(this.token, documentType, file);
  }

  public deleteDocument(document: Document): Promise<void> {
    return this.documentApiService.deleteProvidedFile(this.token, document.file.id);
  }

  public async onSave() {
    if (this.form.invalid) {
      this.showUnfinishedSaved();

      return;
    }

    this.isSubmitting = true;

    try {
      await this.documentApiService.completeDocumentRequest(this.token);
      this.showFinishedSaved();
    }
    catch (error) {
      this.errorMessage = error.error.message;
    }

    this.isSubmitting = false;
  }

  private showUnfinishedSaved() {
    this.toastService.add("Documents saved");

    if (!this.isTokenAuth) {
      this.navigationService.goToDashboard();
    }
  }

  private showFinishedSaved() {
    if (!this.isTokenAuth) {
      this.toastService.add("Documents saved");
      this.navigationService.goToDashboard();
    } else {
      this.navigationService.goToThankYou('Thank you for submitting documents.');
    }
  }

  private async initialize() {
    try {
      this.documentRequest = await this.documentApiService.getDocumentRequestInfo(this.token);
    } catch (error) {
      this.errorMessage = error.error.error.message;
    }

    this.buildForm();
  }

  private buildForm() {
    const controls: FormControl[] = [];

    for (const requested of this.documentRequest.requestedTypes) {
      const { type } = requested;
      const provided = this.documentRequest.providedDocuments
        .filter(pd => pd.type === type && (type !== "OTHER" || requested.otherTypeLabel === pd.otherTypeLabel));
      const control = new FormControl(provided, Validators.required);

      controls.push(control);
    }

    this.form = new FormArray(controls);
  }
}
