import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { environment } from "../../../environments/environment";

interface ErrorMessage {
  error: {
    error: {
      code: string;
      message: string;
    }
  }
}

@Injectable({ providedIn: "root" })
export class NavigationService {
  constructor(
    private router: Router,
    ) { }

  public goToUrl(url: string) { window.location = url as any }

  // root pages
  public goToLogin() { return this.router.navigate(["/login"]) }

  public goToForgotPassword() { return this.router.navigate(["/forgot-password"]) }

  public goToDashboard() { return this.router.navigate(["/dashboard"]) }

  public goToThankYou(message?: string) {
    const extras: NavigationExtras = message
      ? { state: { 'thank-you-message': message } }
      : undefined;

    return this.router.navigate(['/thank-you'], extras);
  }

  public goToError(errorMessage?: string | ErrorMessage) {
    const message = typeof errorMessage === "string"
      ? errorMessage
      : (errorMessage as ErrorMessage)?.error?.error?.message;

    const extras: NavigationExtras = message
      ? { state: { 'error-message': message } }
      : undefined;

    return this.router.navigate(['/error'], extras);
  }

  public goToProtectionCrossroad(protectionCaseId: string) {
    return this.router.navigate(['/protection-fact-find', protectionCaseId, 'crossroad']);
  }

  // fact find pages
  public goToCrossroad(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'crossroad']);
  }

  public goToTermsAndConditions(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'terms-and-conditions']);
  }

  public goToStepTermsAndConditions(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'step-terms-and-conditions']);
  }

  public goToIntegration(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'integration']);
  }

  public goToStepIntegration(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'step-integration']);
  }

  public goToApplicationDetails(applicationId: string, applicantId?: string) {
    const queryParams = { applicantId };

    queryParams.applicantId || delete queryParams.applicantId;

    return this.router.navigate(['/fact-find', applicationId, 'application-details'], { queryParams });
  }

  public goToYourDetails(applicationId: string, applicantId?: string) {
    const queryParams = { applicantId };

    queryParams.applicantId || delete queryParams.applicantId;

    return this.router.navigate(['/fact-find', applicationId, 'your-details'], { queryParams });
  }

  public goToDependants(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'dependants']);
  }

  public goToAddressHistory(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'address-history']);
  }

  public goToEmploymentIncome(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'income']);
  }

  public goToMonthlyOutgoings(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'monthly-outgoings']);
  }

  public goToCreditLiabilities(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'credit-liabilities']);
  }

  public goToAssets(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'assets']);
  }

  public goToPropertyDetails(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'property-details']);
  }

  public goToPaymentDetails(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'payment-details']);
  }

  public goToDocuments(applicationId: string) {
    return this.router.navigate(['/fact-find', applicationId, 'documents']);
  }
}
