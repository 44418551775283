<hfc-header-with-logo *ifMedia="'phone'"></hfc-header-with-logo>
<hfc-desktop-header *ifMedia="'desktop'"></hfc-desktop-header>

<div class="content">
  <div class="dashboard">
    <hfc-user-bar *ifMedia="'desktop'" [user]="user" [showRole]="false" [showEmail]="true"></hfc-user-bar>

    <div class="title">
      Your Active Tasks
    </div>

    <ng-container *ngFor="let task of active">
      <hfc-task-item *ngIf="task.type === 'SIGNATURE_REQUEST'"
        [heading]="TASK_TYPE_NAMES.get(task.type)"
        [description]="SignatureRequestTypeName.get(task.signatureRequestType)"
        iconName="edit"
        [date]="task.timestamp"
        [status]="SignatureRequestStateName.get(task.state)"
        buttonLabel="Sign"
        (buttonClick)="onSignatureRequestClicked(task)">
      </hfc-task-item>

      <hfc-task-box *ngIf="task.type !== 'SIGNATURE_REQUEST'" [task]="task"></hfc-task-box>
    </ng-container>

    <div *ngIf="archived?.length" class="title">
      Archive
    </div>
    <ng-container *ngFor="let task of archived">
      <hfc-task-item *ngIf="task.type === 'SIGNATURE_REQUEST'"
                     [heading]="TASK_TYPE_NAMES.get(task.type)"
                     [description]="SignatureRequestTypeName.get(task.signatureRequestType)"
                     iconName="edit"
                     [date]="task.timestamp"
                     [buttonLabel]="task.state === 'ALL_SIGNED' && task.declarationFile ? 'Download' : null"
                     [status]="SignatureRequestStateName.get(task.state)"
                     (buttonClick)="onArchivedSignatureRequestClicked(task)">
      </hfc-task-item>

      <hfc-task-box *ngIf="task.type !== 'SIGNATURE_REQUEST'" [task]="task"></hfc-task-box>
    </ng-container>
  </div>
</div>

<hfc-footer *ifMedia="'phone'"></hfc-footer>
<hfc-desktop-footer *ifMedia="'desktop'"></hfc-desktop-footer>

<!-- Mobile Help Popup -->
<hfc-help-popup></hfc-help-popup>
