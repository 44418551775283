<div class="container">
  <div class="top">
    <i class="material-icons">{{ iconName }}</i>
    <span class="text">{{ date | date:'d MMM y' }}</span>
  </div>

  <div class="name">
    <span class="text-big-medium">{{ heading }}</span>

    <span class="text-big">{{ description }}</span>
  </div>

  <div class="bottom">
    <span class="progress-bar document-status">{{ status }}</span>

    <!-- <hfc-progress-bar *ngIf="isFactfind" [value]="task.archive ? 100 : task.percentageCompleted" class="progress-bar"></hfc-progress-bar> -->

    <hfc-button type="primary" class="bottom__button" (click)="buttonClick.emit()" *ngIf="buttonLabel">{{ buttonLabel }}</hfc-button>
  </div>
</div>

