<form class="form" [formGroup]="form">
  <a href="/">
    <img class="logo" src="assets/img/Everglades-light-bg.svg">
  </a>

  <div class="title">Login</div>

  <hfc-text-input type="email" placeholder="Email" formControlName="username"></hfc-text-input>

  <div class="password">
    <hfc-text-input type="password" placeholder="Password" formControlName="password"></hfc-text-input>
    <hfc-button postfixIcon="chevron_right" (click)="onForgotClicked()">Forgot?</hfc-button>
  </div>

  <div *ngIf="message" class="error text-red">
    {{ message }}
  </div>
  <div *ngIf="isHeronUser" class="error text-red">
    This interface is for applicants only.<br>
    Trying to get to Everglades for Advisors? <a [href]="adminWebUrl">Click Here</a>
  </div>

  <hfc-button type="primary" [submit]="true" [isLoading]="isSubmitting" (click)="onSubmit()">Login</hfc-button>

  <div class="copyright">© 2019-{{today|date:'yy'}}  Heron Financial  All Rights Reserved</div>
</form>
