import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { NavigationService } from '../../services/navigation.service';
import { User, UserService } from '../../services/user.service';
import { environment } from "../../../../environments/environment";
import {getExceptionMessage} from '../../../shared/functions/get-exception-message';

@Component({
  selector: 'hfc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public form: FormGroup = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  })

  public isSubmitting = false;
  public message: string;
  public isHeronUser = false;
  public adminWebUrl = environment.adminWebUrl;
  public today = new Date();

  constructor(
    private fb: FormBuilder,
    private navigationService: NavigationService,
    private userService: UserService,
    private authService: AuthService
  ) { }

  public onForgotClicked() {
    this.navigationService.goToForgotPassword();
  }

  public async onSubmit() {
    const data = this.form.value;

    try {
      this.isSubmitting = true;

      const user = await this.authService.checkLogin(data) as User;

      if (user.userType !== "CLIENT") {
        this.isHeronUser = true;

        return;
      }

      this.userService.setUser(user);
      this.navigationService.goToDashboard();
    } catch (e) {
      console.log("Error calling login API: ", e);
      this.message = getExceptionMessage(e);
    } finally {
      this.isSubmitting = false;
    }
  }
}
