<hfc-spinner *ngIf="isLoading"></hfc-spinner>

<form *ngIf="!isLoading" [formGroup]="form" class="form">
  <a href="/">
    <img class="logo" src="assets/img/Everglades-light-bg.svg">
  </a>

  <div *ngIf="errorMessage" class="text-h3-medium-grey24">
    {{errorMessage}}

    <div class="goToLogin">
      <hfc-button type="primary" routerLink="/login">Go to Login</hfc-button>
    </div>
  </div>

  <ng-container *ngIf="!errorMessage">
    <div class="text-h3-medium-grey24">
      {{ isReset ? 'Enter a new password' : 'Create Password' }}
    </div>
    <div class="password-hint text-grey60">
      At least 8 characters and a number.
    </div>
    <p class="text">
      Your Email Address: <br>
      <span class="text-medium">{{invitation.email}}</span>

      <input type="text" id="username" name="username" autocomplete="username" [value]="invitation.email" style="display: none;">
    </p>


    <hfc-text-input type="password" placeholder="Enter password" autocomplete="new-password" class="input" formControlName="password"></hfc-text-input>
    <hfc-text-input type="password" placeholder="Re-enter password" autocomplete="new-password" class="input" formControlName="confirmPassword"></hfc-text-input>

    <div *ngIf="form.get('password').errors?.secure && form.get('password').value" class="text-red validation-error">
      Passwords has to be at least 8 characters and a number.
    </div>
    <div *ngIf="form.errors?.nomatch && form.get('confirmPassword').value" class="text-red validation-error">
      Passwords don't match
    </div>

    <hfc-button [isLoading]="isSubmitting" type="primary" [submit]="true" (click)="onSubmit()" [disabled]="!form.valid">Save and Continue</hfc-button>

    <div *ifMedia="'desktop'" class="copyright text-grey60">
      © 2019  Heron Financial  All Rights Reserved
    </div>
  </ng-container>
</form>
