import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavigationService } from '../../services/navigation.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'hfc-unsubscribe-abort',
  templateUrl: './unsubscribe-abort.component.html',
  styleUrls: ['./unsubscribe-abort.component.scss']
})
export class UnsubscribeAbortComponent {
  public message: string;
  public showLogin: boolean;

  public get user() { return this.userService.user }

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private navigationService: NavigationService
  ) {
    this.message = this.route.snapshot.data.message;
    this.showLogin = this.route.snapshot.data.showLogin;
  }

  public onReturnClicked() {
    this.navigationService.goToLogin();
  }
}
