import { Component } from '@angular/core';
import { ClientService, SignatureRequestStateName, SignatureRequestTypeName, SignatureRequestTypeRoute, Task, TASK_TYPE_NAMES } from '../../../root/services/client.service';
import { UserService } from '../../../root/services/user.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../src/environments/environment';

@Component({
  selector: 'hfc-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent {
  public TASK_TYPE_NAMES = TASK_TYPE_NAMES;
  public SignatureRequestTypeName = SignatureRequestTypeName;
  public SignatureRequestStateName = SignatureRequestStateName;
  public SignatureRequestStateRoute = SignatureRequestTypeRoute;

  public get user() { return this.userService.user }

  public active: Task[];
  public archived: Task[];

  constructor(
    private clientService: ClientService,
    private userService: UserService,
    private router: Router,
  ) {
    this.initialize();
  }

  public async onSignatureRequestClicked(task: Task) {
    this.router.navigate([`/signature/${SignatureRequestTypeRoute.get(task.signatureRequestType)}`, task.token]);
  }

  public async onArchivedSignatureRequestClicked(task: Task) {
    let attachment = task.declarationFile;
    if (!attachment) {
      return;
    }

    if (attachment.externalURL) {
      window.open(attachment.externalURL);
    } else {
      window.open(`${environment.apiUrl}servlet/gcs/${attachment.folder}/${attachment.filename}?download=true`);
    }
  }

  private async initialize() {
    try {
      const dashboardData = await this.clientService.getHomeDashboardData();

      this.active = dashboardData.activeTasks;
      this.archived = dashboardData.archivedTasks;
    } catch (e) { }
  }
}
