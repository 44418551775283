import { Component } from '@angular/core';

import { AuthService } from '../../services/auth.service';
import { NavigationService } from '../../services/navigation.service';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'hfc-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  constructor(
    private navigationService: NavigationService,
    private userService: UserService,
    private authService: AuthService
  ) {
    this.logout();
  }

  private async logout() {
    try {
      await this.authService.logOut();
    } catch (e) {
      console.log("Error calling logout service: ", e);
    } finally {
      this.userService.setUser(undefined);
      this.navigationService.goToLogin();
    }
  }
}
