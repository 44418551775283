import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'hfc-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  public form: FormGroup = this.fb.group({
    email: ['', Validators.required],
  })

  public isSubmitting = false;
  public message: string;
  public description: string = "Enter your email address and we’ll send you a link to reset your password.";
  public done = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService
  ) { }

  public async onSubmit() {
    const data = this.form.value;

    try {
      this.isSubmitting = true;
      const email = this.form.get("email").value;

      await this.authService.forgottenPassword(email);

      this.description = `We sent email to ${email}, which contains a link to reset your password.`;
      this.done = true;
    } catch (e) {
      console.log("Error calling login API: ", e);
      this.message = e.error.error.message;
    } finally {
      this.isSubmitting = false;
    }
  }
}
