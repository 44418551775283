import { Injectable } from '@angular/core';

import { AuthGuard } from './auth-required.guard';
import { AuthService } from './auth.service';
import { NavigationService } from './navigation.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AuthOptionalGuard extends AuthGuard {
  constructor(
    protected authService: AuthService,
    protected userService: UserService,
    protected navigationService: NavigationService
  ) {
    super(authService, userService, navigationService);
  }

  public requiresAuth() { return false }
}
