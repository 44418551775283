import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClientService } from '../../services/client.service';

interface UnsubscribeConfig {
  message: string;
  hint?: string;
  buttonLabel: string;
  doneRoute: string;
  abortRoute: string;
}

const configs: { [method: string]: UnsubscribeConfig } = {
  unsubscribe: {
    message: "Are you sure you want to unsubscribe from all notifications about this mortgage?",
    buttonLabel: "Unsubscribe",
    doneRoute: "unsubscribe-done",
    abortRoute: "unsubscribe-abort",
  },
  unsubscribeMortgage: {
    message: "Are you sure you want to unsubscribe from all notifications about this mortgage?",
    buttonLabel: "Unsubscribe",
    doneRoute: "unsubscribe-mortgage-done",
    abortRoute: "unsubscribe-mortgage-abort",
  },
  unsubscribeRemortgage: {
    message: "Are you sure you want to unsubscribe from remortgage information? ",
    hint: "Please note that we are still obliged by the Financial Conduct Authority to notify you of your upcoming product end date nearer the time, regardless of your preference.",
    buttonLabel: "Unsubscribe",
    doneRoute: "unsubscribe-remortgage-done",
    abortRoute: "unsubscribe-remortgage-abort",
  },
  unsubscribeReminder: {
    message: "Are you sure you want to disable this reminder? If so, please keep the email you clicked through on, so that you can find it later without a reminder.",
    buttonLabel: "Disable this reminder",
    doneRoute: "unsubscribe-reminder-done",
    abortRoute: "unsubscribe-reminder-abort",
  }
}

@Component({
  selector: 'hfc-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent {
  public method = this.route.snapshot.data.method;
  public config: UnsubscribeConfig;
  public isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
  ) {
    this.config = configs[this.method];
  }

  public async onUnsubscribeClicked() {
    const token = this.route.snapshot.queryParamMap.get("token");

    if (!token) {
      return;
    }

    try {
      this.isSubmitting = true;

      await this.callMethod(token);

      this.router.navigate([this.config.doneRoute]);
    } catch (err) {
      console.log("Unsubscribe error: ", err);
    }

    this.isSubmitting = false;
  }

  public onAbortClicked() {
    this.router.navigate([this.config.abortRoute]);
  }

  private callMethod(token: string) {
    switch (this.method) {
      case "unsubscribe":
        return this.clientService.unsubscribe(token);

      case "unsubscribeMortgage":
        return this.clientService.unsubscribeMortgage(token);

      case "unsubscribeRemortgage":
        return this.clientService.unsubscribeRemortgage(token);

      case "unsubscribeReminder":
        return this.clientService.unsubscribeReminder(token);
    }
  }
}
