<hfc-header-with-logo *ifMedia="'phone'" [showUser]="false"></hfc-header-with-logo>
<hfc-desktop-header *ifMedia="'desktop'"></hfc-desktop-header>

<div class="content">
  <div class="center">
    <i class="material-icons">check_circle</i>

    <span class="text-h1-medium-grey24">
      Thank you!
    </span>

    <div class="message">
      <ng-container *ngIf="message">{{ message }}</ng-container>
      <ng-container *ngIf="!message">You have completed the Fact Find.</ng-container>

      <ng-container *ngIf="user?.advisor">
        Your advisor, {{user.advisor.fullName}} will be in touch soon.
      </ng-container>
    </div>
  </div>

  <div class="buttons">
    <hfc-button type="primary" (click)="onReturnClicked()">Return to Dashboard</hfc-button>
  </div>
</div>
