import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'hfc-unsubscribe-done',
  templateUrl: './unsubscribe-done.component.html',
  styleUrls: ['./unsubscribe-done.component.scss']
})
export class UnsubscribeDoneComponent {
  public title: string;
  public message: string;

  constructor(
    private route: ActivatedRoute
  ) {
    this.title = this.route.snapshot.data.title;
    this.message = this.route.snapshot.data.message;
  }
}
