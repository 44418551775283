<hfc-header *ifMedia="'phone'" [closeButton]="!isTokenAuth">Provide Document</hfc-header>
<hfc-desktop-header *ifMedia="'desktop'" [logoutButton]="!isTokenAuth"></hfc-desktop-header>

<div class="content">
  <div *ngIf="errorMessage">
    {{errorMessage}}
  </div>

  <div *ngIf="documentRequest" class="document">
    <ng-container *ngIf="user">
      <hfc-user-bar *ifMedia="'desktop'" [user]="user" [showRole]="false" [showEmail]="true"></hfc-user-bar>
    </ng-container>

    <form [formGroup]="form" class="form">
      <div *ifMedia="'desktop'" class="form__title">
        <hfc-button *ngIf="!isTokenAuth" icon="arrow_back" class="form__title__icon" routerLink="/dashboard">
        </hfc-button>
        Provide Document
      </div>

      <div class="form__hint">
        Please include original documentation where possible and ensure any photographs or scanned items are clear and legible.
      </div>

      <div *ngIf="documentRequest.messageToClient" class="form__hint">
        Message from {{documentRequest.requestedBy?.fullName}}:<br /><span [innerHtml]="documentRequest.messageToClient"></span>
      </div>

      <div *ngFor="let documentType of documentRequest.requestedTypes; let i=index" class="field-container">
        <hfc-field [label]="documentType.otherTypeLabel || GENERAL_DOCUMENT_TYPE_NAME.get(documentType.type)" class="full">
          <hfc-fact-find-upload [type]="documentType" [formControlName]="i"></hfc-fact-find-upload>
        </hfc-field>
      </div>

      <hfc-button *ifMedia="'desktop'" type="primary" [isLoading]="isSubmitting" (click)="onSave()" class="form__confirm">Save Documents</hfc-button>
    </form>
  </div>
</div>

<hfc-sticky-footer *ifMedia="'phone'">
  <hfc-button type="primary" (click)="onSave()" [isLoading]="isSubmitting" class="flex-fill">Save Documents</hfc-button>
</hfc-sticky-footer>

<hfc-desktop-footer *ifMedia="'desktop'"></hfc-desktop-footer>
