<div class="content">
  <div class="center">
    <i class="material-icons">error_outline</i>

    <span class="text-h1-medium-grey24">
      Sorry, there's an issue
    </span>

    <div class="message">
      <ng-container *ngIf="message">{{ message }}</ng-container>
    </div>
  </div>
</div>
