import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClientSharedModule } from '../shared/client-shared.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardLayoutComponent } from './components/dashboard-layout/dashboard-layout.component';
import { TaskBoxComponent } from './components/task-box/task-box.component';
import { TaskItemComponent } from './components/task-item/task-item.component';
import { DashboardRoutingModule } from './dashboard-routing.module';


@NgModule({
  declarations: [
    DashboardLayoutComponent,
    TaskBoxComponent,
    TaskItemComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    ClientSharedModule,
  ]
})
export class DashboardModule { }
