import { Environment } from "../../../../../../src/app/website/services/environment.service";

import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable()
export class ClientEnvironment extends Environment {
  constructor() {
    super();

    super.setup(environment);
  }
}
