import { Component, Input } from '@angular/core';

import { GENERAL_DOCUMENT_TYPE_NAME } from '../../../../../../../src/app/website/utils/variables.data';
import { DocumentApiService } from '../../../document/services/document-api.service';
import { Task, TASK_STATUS_NAMES } from '../../../root/services/client.service';
import { NavigationService } from '../../../root/services/navigation.service';

@Component({
  selector: 'hfc-task-box',
  templateUrl: './task-box.component.html',
  styleUrls: ['./task-box.component.scss']
})
export class TaskBoxComponent {
  @Input()
  public task: Task;

  public TASK_STATUS_NAMES = TASK_STATUS_NAMES;

  public isLoading = false;

  constructor(
    private documentApiService: DocumentApiService,
    private navigationService: NavigationService
  ) { }

  public get isFactfind() { return this.task.type !== "REQUEST_DOCUMENT" }

  public onGoToFactfindClicked() {
    this.isLoading = true;

    try {
      this.task.type === "FACT_FIND"
        ? this.navigationService.goToCrossroad(this.task.mortgageId)
        : this.navigationService.goToProtectionCrossroad(this.task.protectionCaseId);
    } catch (e) {
      this.isLoading = false;
    }
  }

  public onArchiveViewClicked() {
    this.task.file && this.documentApiService.triggerDownload(this.task.file);
  }

  public getDocumentLabel(): string {
    if (!this.task) {
      return
    }

    return this.task.requestedTypes;
  }
}
