import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AuthService } from './auth.service';
import { NavigationService } from './navigation.service';
import { UserService } from './user.service';

export abstract class AuthGuard implements CanActivate {
  constructor(
    protected authService: AuthService,
    protected userService: UserService,
    protected navigationService: NavigationService
  ) { }

  public abstract requiresAuth(): boolean;

  public async canActivate(): Promise<boolean> {
    const user = this.userService.user;

    if (user && user.id) {
      return true;
    }

    try {
      const session = await this.authService.checkSigned();

      this.userService.setUser(session);
      return true;
    } catch (e) { }

    return !this.requiresAuth() || this.navigationService.goToLogin();
  }
}

@Injectable({ providedIn: 'root' })
export class AuthRequiredGuard extends AuthGuard {
  constructor(
    protected authService: AuthService,
    protected userService: UserService,
    protected navigationService: NavigationService
  ) {
    super(authService, userService, navigationService);
  }

  public requiresAuth() { return true }
}
