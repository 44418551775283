<div class="content">
  <div class="center">
    <i class="material-icons">check_circle</i>

    <span class="text-h1-medium-grey24">
      Thank you!
    </span>

    <div class="message">
      <ng-container *ngIf="message">{{ message }}</ng-container>
    </div>
  </div>

  <div *ngIf="showLogin" class="buttons">
    <hfc-button type="primary" (click)="onReturnClicked()">Go to Login</hfc-button>
  </div>
</div>
