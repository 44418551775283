import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import {HttpClient} from "@angular/common/http";

export interface UserInvitation {
  id: string;
  email: string;
  userName: string;
  fullName: string;
}

@Injectable({ providedIn: "root" })
export class AuthService {
  private readonly API_NAME = "auth";
  private readonly twoFactorApiName = "servlet/auth/";

  private twoFactorApi: ApiService;

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService,
  ) { 
    this.twoFactorApi = new ApiService(this.httpClient);
    this.twoFactorApi.rawApiURL = this.twoFactorApi.apiURL;
    this.twoFactorApi.apiVersion = "";
  }

  public checkLogin(body: any) {
    return this.twoFactorApi.post(this.twoFactorApiName, 'checkLogin', body).toPromise();
  }

  public checkSigned() {
    return this.twoFactorApi.get(this.twoFactorApiName, 'checkSigned').toPromise();
  }

  public logOut() {
    return this.apiService.get(this.API_NAME, 'logOut').toPromise();
  }

  public forgottenPassword(username: string) {

    return this.apiService.post(this.API_NAME, 'forgottenPassword', { username }).toPromise();
  }

  public getInvitationData(token: string): Promise<UserInvitation> {
    const url = `getInvitationData/${token}`;

    return this.apiService.get(this.API_NAME, url).toPromise();
  }

  public getResetPasswordInfo(token: string): Promise<UserInvitation> {
    const url = `getResetPasswordInfo/${token}`;

    return this.apiService.get(this.API_NAME, url).toPromise();
  }

  public setPassword(token: string, newPassword: string): Promise<any> {
    const url = `setPassword/${token}`;

    return this.apiService.post(this.API_NAME, url, { newPassword }).toPromise();
  }

  public resetPassword(token: string, newPassword: string): Promise<any> {
    const url = `resetPassword/${token}`;

    return this.apiService.post(this.API_NAME, url, { newPassword }).toPromise();
  }
}
