import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'hfc-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent {
  public message: string;

  public get user() { return this.userService.user }

  constructor(
    private router: Router,
    private userService: UserService,
    private navigationService: NavigationService
  ) {
    const extras = this.router.getCurrentNavigation().extras;

    if (extras && extras.state) {
      this.message = extras.state["thank-you-message"]
    }
  }

  public onReturnClicked() {
    this.navigationService.goToDashboard();
  }
}
