<form class="form" [formGroup]="form">
  <a href="/">
    <img class="logo" src="assets/img/Everglades-light-bg.svg">
  </a>

  <div class="title">Forgot Password</div>

  <div class="description">
    {{ description }}
  </div>

  <ng-container *ngIf="!done">
    <hfc-text-input type="email" placeholder="Email" formControlName="email"></hfc-text-input>

    <div *ngIf="message" class="error text-red">
      {{ message }}
    </div>

    <hfc-button type="primary" [disabled]="form.invalid" [submit]="true" [isLoading]="isSubmitting"
      (click)="onSubmit()">Send Password Reset Link</hfc-button>
  </ng-container>

  <div class="copyright">© 2019 Heron Financial All Rights Reserved</div>
</form>
