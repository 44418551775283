import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';

@Component({
  selector: 'hfc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public activated = false;

  constructor(
    private store: Store,
    private titleService: Title
  ) {
    this.store
      .select(state => state.app.documentTitle)
      .subscribe(documentTitle => this.titleService.setTitle(documentTitle));

    try {
      const meta = document.querySelector("meta[name=build]");
      const build = meta.getAttribute("content");
      const date = new Date(build.split("--")[0])

      console.warn(`This version was build at: ${date}`);
    } catch (e) { }
  }
}
