<div class="container">
  <div class="content">
    <img class="logo" src="assets/img/unsubscribe.svg">

    <div class="message" [class.big]="config.hint">{{config.message}}</div>

    <div *ngIf="config.hint" class="hint">
      {{ config.hint }}
    </div>
  </div>

  <div class="buttons">
    <hfc-button type="primary" [isLoading]="isSubmitting" (click)="onUnsubscribeClicked()">{{config.buttonLabel}}</hfc-button>
    <hfc-button type="secondary" (click)="onAbortClicked()">Abort</hfc-button>
  </div>
</div>
